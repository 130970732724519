import { IRequestConfig } from '..';
import { LOGINURL, VERIFYURL } from '../url.helper';

namespace AuthService {

  export const login = (): IRequestConfig => {
    return {
      url: LOGINURL,
      method: 'POST',
      key: 'v1/auth/login',
      payload: 'data',
    };
  };
  export const verify = (): IRequestConfig => {
    return {
      url: VERIFYURL,
      method: 'POST',
      key: 'v1/auth/verify',
      payload: 'data',
    };
  };

  export const profile = (): IRequestConfig => {
    return {
      url: "/v1/dashboard/user/profile",
      method: 'GET',
      key: 'dashboard/user/profile'
    };
  };

}

export default AuthService;
