// export const BASEURL = "http://192.168.8.116:8000/api/";
// export const BASEURLASSETS = "http://192.168.8.116:8000/";

export const BASEURL = "https://api.smartfetus.ca/api/";
export const BASEURLASSETS = "https://api.smartfetus.ca/";


export const APANAURL = `/v1/apana`;
export const APANUPDATEAURL = `/v1/apana/update`;

export const VIBRATIONURL = `/v1/vibration`;
export const VIBRATIONSOUNDURL = `/v1/vibration/sounds`;
export const VIBRATIONUPDATEAURL = `/v1/vibration/update`;


// Auth
export const LOGINURL = `/v1/auth/login`;
export const VERIFYURL = `/v1/auth/verify`;

// export const login = (number: string) => `timeout-test/${number}`;
export const SLEEPTIMEURL = `/v1/sleep/update`;
export const GETSLEEPTIMEURL = `/v1/sleep`;

export const UpdatePeriodicalURL = `/v1/update-periodical-time/update`;
export const GETPeriodicalURL = `/v1/update-periodical-time`;

// appointments
export const AppointmentsSendURL = `/v1/app/user/appointments/send`;
export const AppointmentsUpdateURL = `/v1/app/user/appointments/update`;
export const AppointmentsURL = `/v1/app/user/appointments`;
export const UserDoctorsURL = `/v1/app/user/doctors`;

export const USERDOCTORALLSURL = `/v1/dashboard/user/doctors/requests`;
export const ACCEPTREQUESTSURL = `/v1/dashboard/user/doctors/accept`;

export const USERURL = `/v1/user`;
export const USERUPDATWURL = `/v1/user/update`;
export const REPORTURL = `/v1/report`;

export const SPO2URL = `/v1/spo2`;
export const SPO2UPDATEURL = `/v1/spo2/save`;

export const PatientsURL = `/v1/dashboard/user/search`;
export const MedicalAdviceSendURL = `/v1/app/user/medical-advice/send`;
export const PregnantPatientsURL = `/v1/dashboard/pregnant-patients`;
