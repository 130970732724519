import { useIonRouter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import SpaceComponent from "../../../components/space/space.component";
import HeadingComponent from "../../../components/typography/heading/heading.component";
import ParagraphComponent from "../../../components/typography/paragraph/paragraph.component";
import { useAppDispatch, useAppSelector } from "../../../store";
import { patientsState } from "../../../store/slice/patients.slice";
import { pregnantPatientsRequest } from "../../../store/thunk/patients.thunk";

const HomePage = () => {

    const router = useIonRouter();
    const dispatch = useAppDispatch();

    const { patientsChart } = useAppSelector(patientsState);

    useEffect(() => {
        dispatch(pregnantPatientsRequest());
    }, [])

    return (
        // onIonScroll={(detail) => scroll(detail)} scrollEvents={true}
        <div className="mt-4 md:mt-0 w-full rounded-2xl md:overflow-hidden bg-blue shadow" style={{ backgroundColor: "#FEFBEC" }}>
            <div className="px-6 md:px-32 py-2 md:py-16 flex flex-col md:h-full">

                <div className="flex mt-6 gap-3">
                    <HeadingComponent className="text-primary">All pregnant patients:</HeadingComponent>
                    <HeadingComponent className="text-primary font-bold">61</HeadingComponent>
                </div>

                <div className="mt-16 md:mt-52 relative chart h-80" style={{ minHeight: "40vh" }}>

                    <span className="absolute bottom-0 left-3 md:left-0 h-1 w-full border-b border-dashed border-gray">
                        <ParagraphComponent className="text-red absolute -left-8 bottom-0">0</ParagraphComponent>
                    </span>
                    <span className="absolute bottom-1/3 left-3 md:left-0 h-1 w-full border-b border-dashed border-gray">
                        <ParagraphComponent className="text-red absolute -left-8 bottom-0">5</ParagraphComponent>
                    </span>
                    <span className="absolute bottom-2/3 left-3 md:left-0 h-1 w-full border-b border-dashed border-gray">
                        <ParagraphComponent className="text-red absolute -left-8 bottom-0">10</ParagraphComponent>
                    </span>
                    <span className="absolute -top-1 left-3 md:left-0 h-1 w-full border-b border-dashed border-gray">
                        <ParagraphComponent className="text-red absolute -left-8 -bottom-2">15</ParagraphComponent>
                    </span>

                    <ul className="flex h-full justify-between w-full sm:gap-4 gap-1 lg:gap-8 2xl:gap-12">
                        {
                            patientsChart && patientsChart.map((item, index) => {
                                return (
                                    <li className={`relative w-full h-full bg-b-color rounded-xl ${index < 7 ? "hidden md:block" : ""}`}>
                                        <span className={`absolute left-0 bottom-0 rounded-xl w-full text-center text-white`} style={{ backgroundColor: item.color, height: (Math.round(item.value / 15 * 100)) + "%", fontSize: "13px" }}></span>
                                        <ParagraphComponent className="absolute -bottom-8 center-x text-gray">{item.week}</ParagraphComponent>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <ParagraphComponent className="mt-16 text-center" fontSize="18px" color="#9F9F9F">Dispersion of pregnant women by gestational weeks</ParagraphComponent>


                <SpaceComponent />
            </div>
        </div>
    )
}

export default HomePage;