import { UserModel } from './../../models/profile/user.model';
import { RootState } from '../index';
import { createSlice } from '@reduxjs/toolkit';
import { usersRequest } from '../thunk/users-managment.thunk';

export interface UserManagmentSliceModel {
  users?: UserModel[];
  loading: boolean;
}

const initialState: UserManagmentSliceModel = {
  users: [],
  loading: false
};

const UserManagmentSlice = createSlice({
  name: 'UserManagmentSlice',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {

    builder.addCase(usersRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(usersRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload.data;
    });

    builder.addCase(usersRequest.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const userManagmentState = (state: RootState) => state.userManagment;

export default UserManagmentSlice.reducer;
