import { createSlice } from "@reduxjs/toolkit";
import { combineReducersModel } from "..";
import { Spo2Model } from "../../models/spo2/spo2.model";

export interface Spo2SliceModel {
  spo2?: Spo2Model;
}

const initialState: Spo2SliceModel = {
  spo2: undefined
};

const Spo2Slice = createSlice({
  name: "Spo2Slice",
  initialState,
  reducers: {
    setSpo2(state, { payload }) {
      state.spo2 = payload;
    }
  },
});

export const spo2State = (state: combineReducersModel) => state.spo2;

export const { setSpo2 } = Spo2Slice.actions;
export default Spo2Slice.reducer;
