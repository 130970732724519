import { SleepTimeModel } from '../../models/sleep/sleep.model';
import { createSlice } from "@reduxjs/toolkit";
import { combineReducersModel } from "..";
import { Spo2Model } from "../../models/spo2/spo2.model";

export interface PeriodicalUpdateTimeSliceModel {
  periodicalUpdateTime: number;
}

const initialState: PeriodicalUpdateTimeSliceModel = {
  periodicalUpdateTime: 20
};

const PeriodicalUpdateTimeSlice = createSlice({
  name: "PeriodicalUpdateTimeSlice",
  initialState,
  reducers: {
    setPeriodicalUpdateTime(state, { payload }) {
      state.periodicalUpdateTime = payload;
    }
  },
});

export const periodicalUpdateTimeState = (state: combineReducersModel) => state.periodicalUpdateTime;

export const { setPeriodicalUpdateTime } = PeriodicalUpdateTimeSlice.actions;
export default PeriodicalUpdateTimeSlice.reducer;
