import React from 'react';
import LoadingSvg from '../loading-svg';

type ButtonComponentProps = {
    label?: string;
    active?: boolean;
    onClick?: () => void;
    className?: string;
    children?: React.ReactNode;
    circle?: boolean;
    loading?: boolean;
    disablePadding?: boolean;
}

const ButtonComponent = (props: ButtonComponentProps) => {

    let wrapper = `relative ${!props.className?.includes("rounded") ? "rounded-3xl" : ""} ${(props.active === true ? "gradient" : " border bg-gray text-white")} ${props.className ?? ""}`;

    if (props.circle) {
        wrapper += " w-12 h-12 rounded-full flex items-center justify-center";
    } else {
        if (!wrapper.includes("py-")) {
            wrapper += " py-4";
        }
        wrapper += props.disablePadding ? "" : " px-8";
    }

    return (
        <button onClick={props.onClick} className={wrapper} disabled={!props.active}>

            {
                props.loading && <div className="absolute left-0 top-3"><LoadingSvg /></div>
            }

            {
                props.children ? props.children : props.label
            }
        </button>
    )
}

export default ButtonComponent;