import React, { useEffect, useState } from "react";
import { useIonRouter } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../components/button/button.component";
import Input from "../../components/input/input";
import LogoComponent from "../../components/logo/logo.component";
import ParagraphComponent from "../../components/typography/paragraph/paragraph.component";
import Routes from "../../constant/route.constant";
import { authState } from "../../store/slice/auth.slice";
import { verifyRequest } from "../../store/thunk/auth.thunk";
import { setModal } from "../../store/slice/global.slice";
import { useAppDispatch, useAppSelector } from "../../store";
import { useTimer } from "../../hook/useTimer";
import { getTime } from "../../utils/date";

const OtpPage = () => {
  const dispatch = useAppDispatch();
  const { email, loading, response: requestResponse } = useAppSelector(authState);
  const [code, setCode] = useState<string>("");
  const { time, start } = useTimer();
  const router = useIonRouter();

  useEffect(() => {
    if (!email) {
      router.push(Routes.login.path);
      return;
    }

    start(requestResponse?.seconds ?? 120);
  }, []);

  const verify = () => {
    if (!email) return;

    if (!code) {
      dispatch(setModal({ message: "Please enter verify code." }));
      return;
    }

    dispatch(verifyRequest({ email, code })).then((response) => {
      if (response.meta.requestStatus === "rejected") {
        return;
      }
      const { data } = response.payload as {
        data: { role?: "user" | "doctor" | "admin", image: string };
      };
      localStorage.setItem("image", data.image);
      if (data.role !== "user") {
        router.push(Routes.home.path);
        return;
      }
      dispatch(
        setModal({
          message: "You do not have permission to access this section.",
        })
      );
    });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div
        className="w-96 text-center rounded-2xl shadow px-4 py-4 space-y-12"
        style={{ backgroundColor: "#FEFBEC" }}
      >
        <div className="flex justify-center items-center">
          <LogoComponent />
        </div>

        <div className="md:mx-16 space-y-4">
          <h3 className={`${requestResponse?.success === true ? "text-green" : "text-green"}`}>{requestResponse?.message}</h3>
          <p>
            {getTime(time)}
          </p>
          <Input
            type="number"
            placeholder="Please enter code"
            value={code}
            onChange={(text) => setCode(text)}
            readOnly={false}
            align="text-center"
          />
        </div>
        <div className="flex gap-2 justify-center">
          <img src="/assets/images/medical-advice/reply.svg" alt="" />
          <ParagraphComponent
            className="text-gold cursor-pointer"
            onClick={() => router.goBack()}
          >
            change email
          </ParagraphComponent>
        </div>

        <div className={`flex items-center justify-center`}>
          <ButtonComponent
            className="text-white font-bold"
            label="Verify"
            active={true}
            onClick={verify}
            loading={loading}
          ></ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
