import { IonSpinner } from '@ionic/react';
import React from 'react';

const LoadingSvg = (props: {
    className?: string;
    color?: string;
    // width?: string;
    // height?: string;
    // margin?: string
}) => {
    return (
        <IonSpinner name="crescent" style={{transform: "scale(0.5)", color: props.color ?? "white"}}  />
    )
}

export default LoadingSvg;