
export type RoutesType = {
    path: string;
    title: string;
    isBack?: boolean;
}

interface RoutesItems {
    root: RoutesType;

    // 
    login: RoutesType,
    otp: RoutesType,

    dashboard: RoutesType;
    welcome: RoutesType;
    sleepTime: RoutesType;
    sleepDashboardTime: RoutesType;
    home: RoutesType;
    history: RoutesType;
    profile: RoutesType;
    settings: RoutesType;
    more: RoutesType;
    notification: RoutesType;
    vibration: RoutesType;
    exit: RoutesType;
    detail: RoutesType;
    wristbandConnections: RoutesType;
    periodicalUpdateTime: RoutesType;
    
    // new
    patients: RoutesType;
    userManagment: RoutesType;
    doctors: RoutesType;
    informationPage: RoutesType;
}

export enum RoutesEnum {
    root = '/',
    login = '/auth/login',
    otp = '/auth/otp',
    dashboard = '/dashboard',
    welcome = '/welcome',
    sleepTime = "/initial/sleep-time",
    sleepDashboardTime = "/dashboard/sleep-time",
    home = '/dashboard/home',
    history = '/dashboard/history',
    detail = '/dashboard/detail',
    profile = '/dashboard/profile',
    settings = '/dashboard/settings',
    notification = '/dashboard/notification',
    vibration = '/dashboard/vibration',
    exit = 'exit',
    wristbandConnections = '/dashboard/wristband-connections',
    periodicalUpdateTime = '/dashboard/periodical-updateTime',
    
    informationPage = '/auth/profile',
    morePage = '/dashboard/more',
    patientsPage = '/dashboard/patients',
    userManagmentPage = '/dashboard/user-managment',
    doctors = '/dashboard/doctors'
}

const Routes: RoutesItems = {

    root: {
        path: RoutesEnum.root,
        title: ""
    },

    dashboard: {
        path: RoutesEnum.dashboard,
        title: "dashboard",
    },

    welcome: {
        path: RoutesEnum.welcome,
        title: "welcome",
    },
    sleepTime: {
        path: RoutesEnum.sleepTime,
        title: "sleep",
        isBack: false
    },
    home: {
        path: RoutesEnum.home,
        title: "home",
    },

    history: {
        path: RoutesEnum.history,
        title: "history",
    },

    profile: {
        path: RoutesEnum.profile,
        title: "profile",
    },

    settings: {
        path: RoutesEnum.settings,
        title: "settings",
        isBack: true
    },

    notification: {
        path: RoutesEnum.notification,
        title: "Alarm and Notification",
        isBack: true
    },
    vibration: {
        path: RoutesEnum.vibration,
        title: "Alarm and Vibration",
        isBack: true
    },

    exit: {
        path: RoutesEnum.exit,
        title: ""
    },

    detail: {
        path: RoutesEnum.detail,
        title: "Detail",
        isBack: true
    },

    wristbandConnections: {
        path: RoutesEnum.wristbandConnections,
        title: "Wristband Connections",
        isBack: true
    },
    sleepDashboardTime: {
        path: RoutesEnum.sleepDashboardTime,
        title: "Sleep Time",
        isBack: true
    },
    periodicalUpdateTime: {
        path: RoutesEnum.periodicalUpdateTime,
        title: "Periodical Update Time",
        isBack: true
    },
    login: {
        path: RoutesEnum.login,
        title: "Login"
    },
    otp: {
        path: RoutesEnum.otp,
        title: "Otp"
    },
    informationPage: {
        path: RoutesEnum.informationPage,
        title: "Profile Information"
    },

    more: {
        path: RoutesEnum.morePage,
        title: "More"
    },
    
    patients: {
        path: RoutesEnum.patientsPage,
        title: "Patients"
    },

    userManagment: {
        path: RoutesEnum.userManagmentPage,
        title: "Create user"
    },

    doctors: {
        path: RoutesEnum.doctors,
        title: "doctors"
    },
    
}

export default Routes;