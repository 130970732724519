import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  useIonRouter
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import "./theme/index.scss";
import './theme/output.css'
/* Theme variables */
import './theme/variables.css';
import LoadingComponent from './components/loading/loading.component';
import ToastComponent from './components/toast/toast.component';
import { globalState, resetModal } from './store/slice/global.slice';
import WelcomePage from './pages/welcome/welcome.page';
import DashboardLayout from './layouts/dashboard.layout';
import Routes from './constant/route.constant';
import { useDispatch, useSelector } from 'react-redux';
import ModalAlertComponent from './components/modal-alert/modal.component';
import OtpPage from './pages/auth/otp';
import LoginPage from './pages/auth/login';
import { useEffect } from 'react';
import history from './store/history';
import { userProfileRequest } from './store/thunk/auth.thunk';
// import { userProfileRequest } from './store/thunk/user.thunk';

const App: React.FC = () => {

  const { isLoading, toast, modal } = useSelector(globalState);
  const dispatch = useDispatch();

  const resetModel = () => {
    dispatch(resetModal());
  }  

  return (
    <IonApp>
      <IonReactRouter history={history}>

        <LoadingComponent isLoading={isLoading} />
        <ToastComponent toast={toast} />
        {modal && <ModalAlertComponent data={modal} close={resetModel} />}

        <IonRouterOutlet>
          <Route path={Routes.dashboard.path} component={DashboardLayout} />
          {/* <Route path={Routes.welcome.path} component={WelcomePage} /> */}
          <Route path={Routes.login.path} component={LoginPage} />
          <Route path={Routes.otp.path} component={OtpPage} />
          {/* <Route path={Routes.sleepTime.path} component={SleepTimePage} /> */}
          <Redirect exact from={Routes.root.path} to={Routes.home.path} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
