import { UserModel } from './../../models/profile/user.model';
import ResponseModel from "../../models/response.model";
import GenericThunk from ".";
import AuthService from "../../api/auth";
import {
  verifyRequestInputModel,
  verifyRequestOutputModel,
} from "../../models/verify-request";

export const loginRequest = GenericThunk<
  ResponseModel,
  { email: string }
>(AuthService.login());

export const verifyRequest = GenericThunk<ResponseModel<verifyRequestOutputModel>, verifyRequestInputModel>(AuthService.verify());

export const userProfileRequest = GenericThunk<ResponseModel<UserModel>>(
  AuthService.profile()
);