import { IRequestConfig } from '..';
import { MedicalAdviceSendURL, PatientsURL, PregnantPatientsURL } from '../url.helper';

namespace ListService {
  export const listPatientsRequest = (): IRequestConfig => {
    return {
      url: PatientsURL,
      method: 'GET',
      key: 'dashboard/user/search',
      payload: 'params',
    };
  };
  
  export const medicalAdviceRequest = (): IRequestConfig => {
    return {
      url: MedicalAdviceSendURL,
      method: 'POST',
      key: 'app/user/medical-advice/send',
      payload: 'data',
    };
  };

  export const PregnantPatientsRequest = (): IRequestConfig => {
    return {
      url: PregnantPatientsURL,
      method: 'GET',
      key: 'dashboard/pregnant-patients',
      payload: 'params',
    };
  };

}

export default ListService;
