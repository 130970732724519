import React, { useState } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ButtonComponent from '../button/button.component';

type CropperImageProps = {
    image?: string;
    loading?: boolean;
    onSubmit?: (data: string) => void;
    onCancel?: () => void;
}

const CropperImage = (props: CropperImageProps) => {

    const [cropper, setCropper] = useState<any>();
    const crop = () => {
        if (typeof cropper !== "undefined") {
            const data = cropper.getCroppedCanvas().toDataURL();
            if (props.onSubmit) props.onSubmit(data);
        }
    }

    return (
        <>
            <div className='mt-5'>
                <div style={{ width: "100%" }}>
                    <button className='my-5'>please crop your image</button>
                    <Cropper
                        style={{ height: "400px", width: "100%" }}
                        className="bg-red-600"
                        // zoomTo={1}
                        // initialAspectRatio={1}
                        aspectRatio={1 / 1}
                        // preview=".img-preview"
                        src={props.image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        dragMode="move"
                        // autoCropArea={1}
                        // checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance: any) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </div>
                <div className="flex gap-3 items-center justify-center">

                    <ButtonComponent className="text-white mt-3" active={true} onClick={crop} loading={props.loading} >
                        cropp
                    </ButtonComponent>
                    <ButtonComponent className="text-white mt-3" active={true} onClick={props.onCancel} >
                        cancel
                    </ButtonComponent>
                </div>
                <br style={{ clear: "both" }} />
            </div>
        </>
    )
}

export default CropperImage;