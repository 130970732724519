import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducersModel } from '..';
import { DashboardChart } from '../../models/dashboard/chard';
import { PatientsModel } from '../../models/patients.model';
import { patientsRequest, pregnantPatientsRequest } from '../thunk/patients.thunk';
// import { Dictionary, PximetriesValueModel } from "../../models/oximetries/report.model";

export interface PatientsSliceModel {
  patients?: PatientsModel[];
  patientsOrginal?: PatientsModel[];
  selectedPatients?: PatientsModel;
  patientsChart?: DashboardChart[];
  loading: boolean;
}

const initialState: PatientsSliceModel = {
  patients: [],
  patientsOrginal: [],
  patientsChart: [],
  selectedPatients: undefined,
  loading: false,
};

const PatientsSlice = createSlice({
  name: 'PatientsSlice',
  initialState,
  reducers: {
    // setPatients(state, { payload }) {
    //   state.patients = payload;
    //   state.patientsOrginal = payload;
    // },
    setSelectedPatients(state: any, action: PayloadAction<PatientsModel | undefined>) {
      state.selectedPatients = action.payload;
    },
    searchPatients(state: any, action: PayloadAction<string>) {
      const text = action.payload;
      if (text === '') {
        state.patients = [...(state.patientsOrginal ?? [])];
        return;
      }
      state.patients = state.patientsOrginal?.filter((f: any) =>
        f.name?.toLowerCase().includes(text.toLowerCase())
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patientsRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      patientsRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.patients = action.payload.data;
        state.patientsOrginal = action.payload.data;
      }
    );

    builder.addCase(patientsRequest.rejected, (state) => {
      state.loading = false;
    });



    builder.addCase(pregnantPatientsRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(pregnantPatientsRequest.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.patientsChart = action.payload.data;
    }
    );

    builder.addCase(pregnantPatientsRequest.rejected, (state) => {
      state.loading = false;
    });


  },
});

export const patientsState = (state: combineReducersModel) => state.patients;

export const { setSelectedPatients, searchPatients } = PatientsSlice.actions;
export default PatientsSlice.reducer;
