import React, { useEffect } from 'react'
import { BASEURLASSETS } from '../../../api/url.helper';
import ButtonComponent from '../../../components/button/button.component';
import { DoctorsModel, UserModel } from '../../../models/profile/user.model';
import { useAppDispatch, useAppSelector } from '../../../store';
import { userState } from '../../../store/slice/user.slice';
import { doctorsRequest, acceptRequest } from '../../../store/thunk/user.thunk';

const UserConnections = () => {

    const dispatch = useAppDispatch();
    const { doctors, loading } = useAppSelector(userState);

    useEffect(() => {
        dispatch(doctorsRequest());
    }, [])

    const styleStatus = (doctor_status?: string) => {
        if (doctor_status === "confirm") return "text-green";
        if (doctor_status === "pending") return "text-yellow";
        if (doctor_status === "reject") return "text-red-600";
        return "";
    }

    const accpet = (user: DoctorsModel) => {
        request(user.id, "confirm", "Are you sure the request is approved?")
    }

    const reject = (user: DoctorsModel) => {
        request(user.id, "reject", "Are you sure to cancel the request?")
    }

    const request = (id: number, status: string, message: string) => {
        if (window.confirm(message)) {
            // request(doctor.id);
            dispatch(acceptRequest({ id: id, status: status })).then(response => {
                dispatch(doctorsRequest());
            });
        }
    }



    return (
        <div className="w-full">
            <div className="" style={{ height: "100%" }}>
                <div
                    className="w-full space-y-6 p-3 md:p-6 rounded-2xl overflow-hidden shadow h-full overflow-y-scroll"
                    style={{ backgroundColor: "#FEFBEC" }}
                >
                    <ul className='md:px-8 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4'>
                        {
                            doctors && doctors.map(doctor => {
                                return (
                                    <li key={doctor.id} className='flex justify-center flex-col p-2 border border-gray rounded-xl gap-3 text-center'>
                                        <img src={BASEURLASSETS + doctor.img} alt={doctor.img} className="w-16 mx-auto rounded-full" />
                                        {doctor.name}
                                        <h5 className={`${styleStatus(doctor.status)}`}>{doctor.status ?? "-"}</h5>
                                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
                                            <ButtonComponent active={doctor.status != "confirm"} className="text-white py-1" disablePadding={true} onClick={() => accpet(doctor)}>Accpet</ButtonComponent>
                                            <ButtonComponent active={doctor.status != "reject"} className="text-white py-1" disablePadding={true} onClick={() => reject(doctor)}>Reject</ButtonComponent>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default UserConnections;