import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import HeartRange from "../../../components/heart-range";
import Information from "../../../components/information/information";
import MessagesDoctor from "../../../components/messages-doctor";
import PatientsList from "../../../components/patients-list";
import SliderComponent from "../../../components/slider";
import { PatientsModel } from "../../../models/patients.model";
import { useAppDispatch, useAppSelector } from "../../../store";
import { doctorAppointmentState } from "../../../store/slice/appointment.slice";
import { setToast } from "../../../store/slice/global.slice";
import {
  patientsState,
  setSelectedPatients,
} from "../../../store/slice/patients.slice";
import { appointmentsRequest, appointmentsSendRequest, appointmentUpdateRequest } from "../../../store/thunk/doctor-appointment.thunk";
import { patientsRequest, submitAdviceRequest } from "../../../store/thunk/patients.thunk";

import "./index.scss";

const PatientsPage = () => {
  const dispatch = useAppDispatch();
  const { patients, selectedPatients } = useAppSelector(patientsState);
  // const router = useRout();
  const { appointments } = useAppSelector(doctorAppointmentState);
  const [delay, setDelay] = useState<string>()

  const params = useParams<{ doctor_id: string }>();

  useEffect(() => {
    if (!params.doctor_id) return;
    dispatch(patientsRequest({ doctor_id: Number(params.doctor_id) }));
  }, [params.doctor_id]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedPatients(undefined))
    }
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      dispatch(patientsRequest({ doctor_id: Number(params.doctor_id), name: delay !== "" ? delay : undefined }));
    }, 1000);
    return () => {
      clearTimeout(interval)
    }
  }, [delay])

  const onSearch = (text: string) => {
    setDelay(text);
  };

  const onSelect = (item: PatientsModel) => {
    dispatch(setSelectedPatients(item));
    dispatch(appointmentsRequest({ user_id: item?.id }));
  };

  const onSubmit = (message: string, to_user_id?: number, message_id?: number, subject?: string) => {
    dispatch(submitAdviceRequest({ message, to_user_id: (to_user_id ?? selectedPatients?.id), message_id, subject })).then((response) => {
      if (response.meta.requestStatus === "rejected") return;
      const { message } = response.payload as { message?: string }
      dispatch(setToast({ message: message, status: true }))
    });
  }
  const onSubmitAppointments = (date: string) => {
    if (!selectedPatients?.id) return;
    dispatch(appointmentsSendRequest({ user_id: selectedPatients?.id, reserve_time: date }));
  }

  const onUpdateAppointment = (id: number, status: "done" | "canceled") => {
    dispatch(appointmentUpdateRequest({ id, status, user_id: selectedPatients?.id }));
  }

  // const onTabAppointmentChange = () => {
  //   dispatch(appointmentsRequest({user_id: selectedPatients?.id}));
  // }

  return (
    <div className="w-full">
      <div className="grid grid-cols-12 gap-8" style={{ height: "100%" }}>
        {/* -{JSON.stringify(patients)}- */}
        <PatientsList
          patients={patients}
          selectedPatients={selectedPatients}
          onSelecte={onSelect}
          onSearch={onSearch}
        />
        {/* -{JSON.stringify(selectedPatients)}- */}
        {selectedPatients && (
          <>
            <div className="col-span-12 md:col-span-6 gap-8 rounded-2xl overflow-hidden">
              <div
                className="overflow-hidden h-32 row-span-1 relative"
                style={{ direction: "ltr" }}
              >
                <SliderComponent week={selectedPatients.week} />
              </div>
              <HeartRange patient={selectedPatients} />
              <MessagesDoctor
                medicals={selectedPatients.medical_advice}
                appointments={appointments}
                onSubmit={onSubmit}
                onSubmitAppointments={onSubmitAppointments}
                onUpdateAppointment={onUpdateAppointment}
              />
            </div>
            <div
              className="col-span-12 md:col-span-3 rounded-2xl overflow-hidden shadow h-full px-4 py-4"
              style={{ backgroundColor: "#FEFBEC" }}
            >
              <Information patient={selectedPatients} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PatientsPage;
