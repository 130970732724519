import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineReducersModel } from "..";
import { ModalModel } from "../../models/modal.model";
import { ToastModel } from "../../models/toast.model";
// import { ErrorModel } from "../../models/error.model";
// import { UserModel } from '../../models/users.model'

export interface GlobalSliceModel {
  notification: boolean;
  // sensorModal: boolean;
  isLoading: boolean;
  // showSearch: boolean;
  clearFilter: boolean
  isMenu: boolean;
  // error: ErrorModel | undefined;
  toast?: ToastModel;

  modal: ModalModel | undefined;
  redirectTo?: string;
}

const initialState: GlobalSliceModel = {
  notification: false,
  // sensorModal: false,
  isLoading: false,
  // showSearch: false,
  clearFilter: true,
  isMenu: false,
  // error: undefined,
  toast: undefined,
  // range: undefined
  modal: undefined,
  redirectTo: undefined
};


const GlobalSlice = createSlice({
  name: "GlobalSlice",
  initialState,
  reducers: {
    setNotifiation(state, { payload }) {
      state.notification = payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    // hasError: (state, action: PayloadAction<ErrorModel | undefined>) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    setToast: (state, action: PayloadAction<ToastModel | undefined>) => {
      state.isLoading = false;
      state.toast = action.payload;
    },
    // resetError: (state) => {
    //   state.error = undefined;
    // },
    resetToast: (state) => {
      state.toast = undefined;
    },
    // setRange: (state, action: PayloadAction<SensorTypeRangeModel | undefined>) => {
    //   state.range = action.payload;
    // },
    // setShowSearch: (state, action: PayloadAction<boolean>) => {
    //   state.clearFilter = false;
    //   state.showSearch = action.payload;
    // },
    setClearFilter: (state, action: PayloadAction<boolean>) => {
      state.clearFilter = action.payload;
    },
    // setSensorModal: (state, action: PayloadAction<boolean>) => {
    //   state.sensorModal = action.payload;
    // },
    showMenu: (state, action: PayloadAction<boolean>) => {
      state.isMenu = action.payload;
    },

    setModal: (state, action: PayloadAction<ModalModel | undefined>) => {
      state.isLoading = false;
      state.modal = action.payload;
    },
    resetModal: (state) => {
      state.modal = undefined;
    },

  },
});

export const globalState = (state: combineReducersModel) => state.global;

// hasError, resetError, , setRange
// setShowSearch, setSensorModal
export const { showMenu, setClearFilter, setNotifiation, startLoading, setToast, resetToast, stopLoading, resetModal, setModal } = GlobalSlice.actions;
export default GlobalSlice.reducer;
