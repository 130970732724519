import { IonContent, useIonRouter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import Routes from "../constant/route.constant";
import HomePage from "../pages/dashboard/home/home.page";
import SettingPage from "../pages/dashboard/setting/setting.page";
import MasterLayout from "./master.layout";
import PatientsPage from "../pages/dashboard/patients";
import LoadingComponent from "../components/loading/loading.component";
// import { setMobile, setNewUser } from '../store/slice/auth.slice';
import UserManagmentPage from "../pages/dashboard/user-managment";
import DoctorsPage from "../pages/dashboard/doctors";
import { useAppDispatch, useAppSelector } from "../store";
import { userState } from "../store/slice/user.slice";
import { userProfileRequest } from "../store/thunk/auth.thunk";
import UserConnections from "../pages/dashboard/user-connections";
import MorePage from "../pages/dashboard/more/more.page";

const DashboardLayout = () => {
  const [login, setLogin] = useState<boolean>(false);
  const { profile } = useAppSelector(userState);
  const dispatch = useAppDispatch();

  const router = useIonRouter();
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (!user_id) {
      router.push(Routes.login.path);
    } else {
      // useEffect(() => {
      // }, [])
      setLogin(true);
      dispatch(userProfileRequest());
    }
    // dispatch(setMobile(""));
  }, []);

  if (login === false) {
    return <LoadingComponent isLoading={true} />;
  }

  return (
    <MasterLayout>
      <Switch>
        <Route path={Routes.home.path} component={HomePage} />
        {/* <Route path={Routes.history.path} component={HistoryPage} /> */}
        {/* <Route path={Routes.detail.path + "/:date"} component={HistoryPage} /> */}
        {/* <Route path={Routes.detail.path} component={HistoryPage} /> */}
        <Route path={Routes.settings.path} component={SettingPage} />
        <Route path={Routes.userManagment.path} component={UserManagmentPage} />
        <Route path={Routes.doctors.path} component={DoctorsPage} />

        {/* <Route path={Routes.notification.path} component={NotificationsPage} /> */}
        {/* <Route path={Routes.vibration.path} component={VibrationPage} /> */}
        {/* <Route path={Routes.wristbandConnections.path} component={WristbandConnections} /> */}
        {/* <Route path={Routes.sleepDashboardTime.path} component={SleepTimeDashboardPage} /> */}
        {/* <Route path={Routes.periodicalUpdateTime.path} component={PeriodicalUpdateTime} /> */}
        <Route path={Routes.more.path} component={MorePage} />
        <Route path={Routes.patients.path} component={PatientsPage} />
        <Route
          path={"/dashboard/:doctor_id/patients"}
          component={PatientsPage}
        />
        <Route
          path={"/dashboard/:doctor_id/connections"}
          component={UserConnections}
        />

        <Redirect from={Routes.dashboard.path} to={Routes.home.path} />
      </Switch>
    </MasterLayout>
  );
};

export default DashboardLayout;
