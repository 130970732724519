import { useDispatch } from 'react-redux';
import { BASEURLASSETS } from '../../api/url.helper';
import { PatientsModel } from '../../models/patients.model';
import { UserInfoModel } from '../../models/profile/user.model';
import HeadingComponent from '../typography/heading/heading.component';
import ParagraphComponent from '../typography/paragraph/paragraph.component';


type InformationProps = {
    data?: UserInfoModel;
    isProfile?: boolean;
    patient: PatientsModel;
}


const Information = (props: InformationProps) => {

    return (
        <div className="space-y-6">

            <div className="flex gap-4 items-center mt-5">
                <div className="text-center">
                    <img className="w-24 relative z-0 rounded-full" src={BASEURLASSETS + props.patient.img} alt="" />
                </div>
                <div className="">
                    <HeadingComponent color="#707070" fontSize="22px" className="text-gray text-left">{props.patient.name}</HeadingComponent>
                    <ParagraphComponent color="#69717B">{props.patient.week}th week</ParagraphComponent>
                </div>
            </div>
            <div className="">
                <ParagraphComponent className="text-gray text-left">Date of Birth</ParagraphComponent>
                <ParagraphComponent className="text-blank text-left">{props.patient.extra?.user_dateOfBirth}</ParagraphComponent>
            </div>

            <div className="">
                <ParagraphComponent className="text-gray text-left">Height</ParagraphComponent>
                <ParagraphComponent className="text-blank text-left">{props.patient.extra?.user_height} cm</ParagraphComponent>
            </div>

            <div className="">
                <ParagraphComponent className="text-gray text-left">Weight</ParagraphComponent>
                <ParagraphComponent className="text-blank text-left">{props.patient.extra?.user_weight} kg</ParagraphComponent>
            </div>

            <div className="">
                <ParagraphComponent className="text-gray text-left">Date of pregnancy</ParagraphComponent>
                <ParagraphComponent className="text-blank text-left">{props.patient.extra?.user_dateOfPregnancy}</ParagraphComponent>
            </div>

            <div className="">
                <ParagraphComponent className="text-gray text-left">Email</ParagraphComponent>
                <ParagraphComponent className="text-blank text-left">{props.patient.email}</ParagraphComponent>
            </div>

            <div className="">
                <ParagraphComponent className="text-gray text-left">Address</ParagraphComponent>
                <ParagraphComponent className="text-blank text-left">{props.patient.extra?.user_address}</ParagraphComponent>
            </div>

            <div className="">
                <ParagraphComponent className="text-gray text-left">Mesical records</ParagraphComponent>
                <ParagraphComponent className="text-blank text-left">{props.patient.extra?.user_medical_records}</ParagraphComponent>
            </div>

            {/* <a href="" className="underline block">Removing this patient’s account</a> */}
        </div>
    )
}

export default Information;