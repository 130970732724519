import React, { useEffect, useState } from "react";
import ButtonComponent from "../../components/button/button.component";
import Input from "../../components/input/input";
import LogoComponent from "../../components/logo/logo.component";
import ParagraphComponent from "../../components/typography/paragraph/paragraph.component";
import Routes from "../../constant/route.constant";
import { useIonRouter } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../store";
import { setModal } from "../../store/slice/global.slice";
import { loginRequest } from "../../store/thunk/auth.thunk";
import { authState } from "../../store/slice/auth.slice";

const LoginPage = () => {
  const [email, setEmail] = useState<string>("");

  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const { email: emailState, error, loading } = useAppSelector(authState);

  useEffect(() => {
    setEmail(emailState ?? "");
  }, [emailState]);

  useEffect(() => {
    if (!error) return;
    dispatch(setModal({ message: error }))
  }, [error])

  const login = () => {
    if (!email) {
      dispatch(setModal({ message: "Please enter email number" }));
      return;
    }
    dispatch(loginRequest({ email })).then((response) => {
      if (response.meta.requestStatus === "rejected") {
        return;
      }
      router.push(Routes.otp.path);
    });
  };

  function ValidateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div
        className="w-full sm:w-6/12 md:w-4/12 xl:w-3/12 text-center rounded-2xl shadow mx-3 px-2 md:px-4 py-4 space-y-12"
        style={{ backgroundColor: "#FEFBEC" }}
      >
        <div className="flex justify-center items-center">
          <LogoComponent />
        </div>
        <div className="md:mx-8 space-y-4">
          <ParagraphComponent className="text-gray">
            Email Address
          </ParagraphComponent>
          <Input
            type="email"
            value={email}
            onChange={(text) => setEmail(text)}
            readOnly={false}
            align="text-center"
          />
        </div>
        <div className={`flex items-center justify-center`}>
          <ButtonComponent
            className="text-white font-bold"
            circle={false}
            label="Continue"
            active={ValidateEmail()}
            onClick={login}
            loading={loading}
          ></ButtonComponent>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
