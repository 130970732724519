import { IRequestConfig } from "..";
import { AppointmentsSendURL, AppointmentsUpdateURL, AppointmentsURL, USERDOCTORALLSURL, ACCEPTREQUESTSURL, UserDoctorsURL } from "../url.helper";

namespace AppointmentsService {

  export const doctorsRequest = (): IRequestConfig => {
    return {
      url: UserDoctorsURL,
      method: "GET",
      key: "app/user/doctors",
    };
  };

  export const appointments = (): IRequestConfig => {
    return {
      url: AppointmentsURL,
      method: "GET",
      key: "app/user/appointments",
      payload: "params"
    };
  };

  export const appointmentsSend = (): IRequestConfig => {
    return {
      url: AppointmentsSendURL,
      method: "POST",
      key: "app/user/appointments/send",
      payload: "data",
    };
  };

  export const appointmentsUpdate = (): IRequestConfig => {
    return {
      url: AppointmentsUpdateURL,
      method: "PATCH",
      key: "app/user/appointments/update",
      payload: "data",
    };
  };

  export const doctors = (): IRequestConfig => {
    return {
      url: USERDOCTORALLSURL,
      method: "GET",
      key: "app/user/doctors"
    };
  };

  export const acceptRequest = (): IRequestConfig => {
    return {
      url: ACCEPTREQUESTSURL,
      method: "POST",
      key: "app/user/doctors/accept",
      payload: "data"
    };
  };

}

export default AppointmentsService;
