import { UserModel } from "./../../models/profile/user.model";
import ResponseModel from "../../models/response.model";
import GenericThunk from ".";
import UserManagmentService from "../../api/user-managment";

export const createUserRequest = GenericThunk<
  ResponseModel,
  {
    email: string;
    name: string;
    // role: "doctor" | "user";
    status: 1 | 0;
    image: string;
  }
>(UserManagmentService.user_create());

export const usersRequest = GenericThunk<
  ResponseModel<UserModel[]>,
  {
    user_type: "doctor" | "user";
  }
>(UserManagmentService.users());

export const updateStatusRequest = GenericThunk<
  ResponseModel,
  {
    user_id: number;
    disable: boolean;
  }
>(UserManagmentService.updateStatus());

// export const searchUserRequest = GenericThunk<
//   ResponseModel,
//   {
//     name?: string;
//     email?: string;
//   }
// >(UserManagmentService.userSearch());
