import { UserModel } from './../../models/profile/user.model';
import { createSlice } from '@reduxjs/toolkit';
import { combineReducersModel } from '..';
import { loginRequest, userProfileRequest, verifyRequest } from '../thunk/auth.thunk';
import ResponseModel from '../../models/response.model';

export interface AuthSliceModel {
  email?: string;
  loading: boolean;
  profile?: UserModel;
  error?: string;

  response?: {
    message?: string;
    seconds?: number;
    success?: boolean;
  }
}

const initialState: AuthSliceModel = {
  email: undefined,
  loading: false,
};

const AuthSlice = createSlice({
  name: 'AuthSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.email = action.meta.arg.email;

      state.response = action.payload.data;

    });

    builder.addCase(loginRequest.rejected, (state, action) => {
      state.loading = false;
      const {message} = action.payload as ResponseModel;
			state.error = message;
    });
    //=====================================================
    builder.addCase(verifyRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(verifyRequest.fulfilled, (state, action) => {
      state.loading = false;
      localStorage.setItem('user_id', action.payload.data?.user_id + '');
      localStorage.setItem(
        'access_token',
        action.payload.data?.access_token + ''
      );
      localStorage.setItem('image', action.payload.data?.image + '');
      state.profile = action.payload.data?.user;
    });

    builder.addCase(verifyRequest.rejected, (state) => {
      state.loading = false;
    });
    /// =========
    /// ============================================================
    builder.addCase(userProfileRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userProfileRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload.data;
    });

    builder.addCase(userProfileRequest.rejected, (state, action) => {
      state.loading = false;
    });

  },
});

export const authState = (state: combineReducersModel) => state.auth;

// export const { setMobile, setNewUser } = AuthSlice.actions;
export default AuthSlice.reducer;
