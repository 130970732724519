import { SleepTimeModel } from './../../models/sleep/sleep.model';
import { createSlice } from "@reduxjs/toolkit";
import { combineReducersModel } from "..";
import { Spo2Model } from "../../models/spo2/spo2.model";

export interface SleepSliceModel {
  sleep?: SleepTimeModel;
}

const initialState: SleepSliceModel = {
  sleep: undefined
};

const SleepSlice = createSlice({
  name: "SleepSlice",
  initialState,
  reducers: {
    setSleep(state, { payload }) {
      state.sleep = payload;
    }
  },
});

export const sleepState = (state: combineReducersModel) => state.sleep;

export const { setSleep } = SleepSlice.actions;
export default SleepSlice.reducer;
