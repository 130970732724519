import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComponent from '../components/header/header.component';
import ModalComponent from '../components/modal/modal.component';
import TabbarComponent from '../components/tabbar/tabbar.component';
import Routes, { RoutesEnum, RoutesType } from '../constant/route.constant';
import {
  globalState,
  showMenu,
  startLoading,
} from '../store/slice/global.slice';
import MenuModalComponent from '../components/menu/menu-modal.component';
import { setActiveApana, apanaState } from '../store/slice/apana.slice';
import history from '../store/history';
import ParagraphComponent from '../components/typography/paragraph/paragraph.component';
import { useAppSelector } from '../store';
import { userState } from '../store/slice/user.slice';
import { authState } from '../store/slice/auth.slice';
// import { setMobile, setNewUser } from '../store/slice/auth.slice';

type MasterLayoutProps = {
  children?: React.ReactNode;
};

const MasterLayout = (props: MasterLayoutProps) => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const { isMenu } = useSelector(globalState);
  const { apana } = useSelector(apanaState);

  const { profile } = useAppSelector(authState);

  const onRoute = (rout: string) => {
    if (rout === RoutesEnum.exit) {
      localStorage.clear();
      // dispatch(setMobile(""));
      dispatch(showMenu(false));
      history.push(Routes.login.path);
      return;
    }

    dispatch(showMenu(false));
    history.push(rout);
  };

  const getRoute = () => {
    const values: RoutesType[] = Object.values(Routes);

    let path = router.routeInfo.pathname;

    if (router.routeInfo.pathname.includes('dashboard/detail')) {
      path = '/dashboard/detail';
    }

    const find = values.find((f) => f.path === path);
    // debugger;

    return find;
  };

  // const apanaActive = (status: boolean) => {
  //   dispatch(apanaUpdateRequest(status));
  // };

  // const onRouter = (route: string) => {
  //     router.push(route);
  // }

  const menuItem = (route: string, name: string, img: string) => {
    return (
      <li
        className="flex flex-col items-center cursor-pointer"
        onClick={() => onRoute(route)}
      >
        <img
          className={`${router.routeInfo.pathname === route ? 'bg-white' : ''
            } p-3 rounded-xl mb-1`}
          src={`/assets/images/sidebar/${img + (router.routeInfo.pathname === route ? '-active' : '')
            }.svg`}
          alt=""
        />
        <ParagraphComponent
          className={`${router.routeInfo.pathname === route ? 'opacity-60' : 'opacity-40'
            }`}
          color={`#F7F4E6`}
        >
          {name}
        </ParagraphComponent>
      </li>
    );
  };

  return (
    <IonContent>
      <HeaderComponent
        route={getRoute()}
        onRoute={(route) => router.push(route)}
        openMenu={() => dispatch(showMenu(true))}
        // apnea={apana}
        // onApana={apanaActive}
      />
      <div
        className="flex flex-col md:flex-row my-4 md:my-8 px-4"
        style={{ minHeight: '85vh' }}
      >
        <div className="md:w-52 relative mb-4 md:mb-0">
          <div className="flex flex-col justify-between rounded-2xl gap-6 py-3 md:mr-10 shadow h-full bg-primary sticky top-10" style={{ maxHeight: "85vh" }}>
            <ul className="mt-4 md:pt-12 grid grid-cols-3 md:grid-cols-1 gap-4">
              {menuItem(Routes.home.path, 'Dashboard', 'dashboard')}
              {
                profile?.role === "doctor" ? (
                  <>
                    {menuItem("/dashboard/" + profile.id + "/patients", 'Patients', 'patients')}
                    {menuItem("/dashboard/" + profile.id + "/connections", 'Connections', 'patients')}
                  </>
                ) : (
                  <>
                    {menuItem(Routes.doctors.path, Routes.doctors.title, 'patients')}
                    {menuItem(Routes.userManagment.path, Routes.userManagment.title, 'patients')}
                  </>
                )
              }
              { }
              { }

            </ul>
            <ul className="md:py-8 gap-4 grid grid-cols-2 md:grid-cols-1">
              {menuItem(Routes.settings.path, 'Settings', 'setting')}
              {menuItem(Routes.exit.path, 'Log out', 'logout')}
            </ul>
          </div>
        </div>
        {props.children}
      </div>
    </IonContent>
  );
};

export default MasterLayout;
