import React from 'react';
import { PatientsModel } from '../../models/patients.model';
import HeadingComponent from '../typography/heading/heading.component';
import ParagraphComponent from '../typography/paragraph/paragraph.component';


type HeartRangeProps = {
    patient: PatientsModel;
}

const HeartRange = (props: HeartRangeProps) => {

    return (
        <div className="md:mx-auto overflow-hidden rounded-3xl relative row-span-2" style={{ backgroundSize: "cover", backgroundImage: "url(/assets/images/home/background.png)" }}>
            {/* -{JSON.stringify(props.patient)}- */}
            <ul>
                {
                    props.patient.report && props.patient.report.map((item, index) => {
                        return (
                            <li key={index} className={`grid grid-cols-3 items-center justify-center px-8 py-4`} style={{ backgroundColor: "rgba(253, 114, 121,0.6)" }}>
                                <div className="">
                                    <div className="flex items-center justify-start gap-2">
                                        <img src="/assets/images/home/heart2.svg" className="w-5 h-5" />
                                        <HeadingComponent fontSize="28px" className="text-white font-bold">{(item.values && item.values.length > 0) ? Math.round(Number(item.values[0].value)) : "-"}</HeadingComponent>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={`assets/images/chart/heart-rate.svg`} className="h-16" alt="" />
                                </div>
                                <ParagraphComponent className="text-gray-text text-right" color="#F0EEE5" fontSize="20px">{item.date}</ParagraphComponent>
                            </li>
                        )
                    })
                }
            </ul>


        </div>
    )
}

export default HeartRange;