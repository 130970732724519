import AxiosHelper from '../../api/axios.helper';
import ResponseSimpleModel from '../../models/response-simple.model';
import ResponseModel from '../../models/response.model';
import { setModal, startLoading, stopLoading } from '../slice/global.slice';
import history from '../history';
import {
  APANAURL,
  APANUPDATEAURL,
  LOGINURL,
  PatientsURL,
  VERIFYURL,
} from '../../api/url.helper';
import Routes from '../../constant/route.constant';
// import { setMobile } from "../slice/auth.slice";
import { setActiveApana } from '../slice/apana.slice';
import { PatientsModel } from '../../models/patients.model';
// import { setPatients, setSelectedPatients } from '../slice/patients.slice';
import GenericThunk from '.';
import ListService from '../../api/list';
import { MedicalAdviceModel } from '../../models/medical_advice';
import { DashboardChart } from '../../models/dashboard/chard';

export const patientsRequest = GenericThunk<ResponseModel<PatientsModel[]>, {doctor_id?: number, name?: string, email?: string}>(
  ListService.listPatientsRequest()
);

export const submitAdviceRequest = GenericThunk<ResponseModel<MedicalAdviceModel[]>, {message: string, to_user_id?: number, message_id?: number, subject?: string}>(
  ListService.medicalAdviceRequest()
);

export const pregnantPatientsRequest = GenericThunk<ResponseModel<DashboardChart[]>>(
  ListService.PregnantPatientsRequest()
);
