import React from 'react';
import { ModalModel } from '../../models/modal.model';
import ButtonComponent from '../button/button.component';
import HeadingComponent from '../typography/heading/heading.component';
import ParagraphComponent from '../typography/paragraph/paragraph.component';

import "./modal.component.scss";

type ModalAlertComponentProps = {
    // title?: string;
    // titleElement?: React.ReactNode;
    // img?: string;
    // message?: string;
    close?: () => void;
    data?: ModalModel;
}

const ModalAlertComponent = (props: ModalAlertComponentProps) => {

    const close = () => {
        if (props.close) {
            props.close();
        }
    }

    if (!props.data) return <></>;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                <div className="fixed inset-0 bg-gray-500 bg-black opacity-60 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={close}></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true" onClick={close}>&#8203;</span>

                <div className="modal-alert shadow inline-block align-bottom rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <img src="/assets/images/close.svg" className="absolute right-4 top-4 p-3 cursor-pointer" alt="" onClick={close} />
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="my-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            {props.data.title && (
                                <HeadingComponent>{props.data.title}</HeadingComponent>
                            )}
                            {props.data.img && (
                                <img src={props.data.img} alt={props.data.title} className="w-12 mx-auto mb-4" />
                            )}
                            {props.data.titleElement && props.data.titleElement}
                            {
                                props.data.message && (
                                    <div className="py-5 text-center">
                                        <HeadingComponent>{props.data.message}</HeadingComponent>
                                    </div>
                                )
                            }
                            {/* <ParagraphComponent>The most floors you have climbed the stairs</ParagraphComponent> */}
                        </div>
                    </div>
                    {/* <div className="bg-gray-50 px-4 py-3 sm:px-6 justify-center flex">
                        <ButtonComponent onClick={close} label="ok" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ModalAlertComponent;