import { createSlice } from "@reduxjs/toolkit";
import { combineReducersModel } from "..";
import { PximetriesModel } from "../../models/oximetries/report.model";

export interface PximetriesSliceModel {
  pximetries: PximetriesModel[];
  pximetriesDetail: PximetriesModel[];
  chart: PximetriesModel[];
}

const initialState: PximetriesSliceModel = {
  pximetries: [],
  pximetriesDetail: [],
  chart: []
};

const PximetriesSlice = createSlice({
  name: "PximetriesSlice",
  initialState,
  reducers: {
    setPximetries(state, { payload }) {
      state.pximetries = payload;
    },
    setPximetriesDetail(state, { payload }) {
      state.pximetriesDetail = payload;
    },
    setChart(state, { payload }) {
      state.chart = payload;
    },
  },
});

export const pximetriesState = (state: combineReducersModel) => state.pximetries;

export const { setPximetries, setPximetriesDetail, setChart } = PximetriesSlice.actions;
export default PximetriesSlice.reducer;
