import React from 'react';

type LogoProps = {
    onClick?: () => void;
}
const LogoComponent = (props: LogoProps) => {
    return (
        <img src="/assets/images/logo.svg" alt="Pulse Oximetry" className={`w-28 ${props.onClick ? "cursor-pointer" : ""}`} onClick={props.onClick} />
    )
}

export default LogoComponent;