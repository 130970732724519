import React from 'react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import ParagraphComponent from '../../../components/typography/paragraph/paragraph.component';
import Routes, { RoutesEnum } from '../../../constant/route.constant';

import {
    IonIcon, useIonRouter,
} from "@ionic/react";


import "./more.page.scss";
import HeadingComponent from '../../../components/typography/heading/heading.component';

type MenuModel = {
    title?: string;
    icon?: string;
    route?: string;
}

const MorePage = () => {

    const router = useIonRouter();

    const menus: MenuModel[] = [
        {
            title: "Settings",
            icon: "/assets/images/menu/setting.svg",
            route: Routes.settings.path
        },
        {
            title: "Exit",
            icon: "/assets/images/menu/logout.svg",
            route: Routes.exit.path
        },
    ]

    const onClick = (item: MenuModel) => {
        if(item.route === Routes.exit.path) {
            localStorage.clear();
            document.location.href = RoutesEnum.welcome;    
            return;
        }
        router.push(item.route ?? "/");
    }

    return (
        <div className="setting mx-4 md:mx-auto max-w-3xl">

            <div className="px-2 flex justify-between mt-8">
                <HeadingComponent className="text-primary">More</HeadingComponent>
                <div className="">
                    {/* <div className="rounded-xl w-16 h-8 bg-gray-gold flex justify-center items-center" onClick={logout}>
                        <ParagraphComponent className="text-heart">Logout</ParagraphComponent>
                    </div> */}
                </div>
            </div>

            <ul className="mt-12 space-y-4">
                {
                    menus.map(menu => {
                        return (
                            <>
                                <li className="px-8 flex items-center border-b-color py-5 cursor-pointer border rounded-full" onClick={() => onClick(menu)}>
                                    <img src={menu.icon} className='mr-2 w-6 h-6' alt="" />
                                    <ParagraphComponent className="text-gray" fontSize="22px">{menu.title}</ParagraphComponent>
                                    <IonIcon icon={chevronForward} className="ml-auto text-gray" size="small" />

                                </li>
                                {/* <div className="bg-gray w-full opacity-30" style={{ height: "1px" }}></div> */}
                            </>

                        )
                    })
                }
            </ul>
        </div>
    )
}

export default MorePage;