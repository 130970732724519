import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BASEURLASSETS } from "../../api/url.helper";
import { PatientsModel } from "../../models/patients.model";
import {
  searchPatients,
  setSelectedPatients,
} from "../../store/slice/patients.slice";
// import { searchPatients, setSelectedPatients } from '../../store/slice/patients.slice';
import HeadingComponent from "../typography/heading/heading.component";
import ParagraphComponent from "../typography/paragraph/paragraph.component";

// type PatientsType = {
//     id: number;
//     name: string;
//     img: string;
//     age: number;
//     week: number;
//     phone: string;

// }

// const data: PatientsType[] = [
//     {
//         id: 1,
//         name: "Olivia Brown",
//         img: "/assets/images/users/user1.svg",
//         age: 29,
//         week: 20,
//         phone: "+1 645 3002",
//     },
//     {
//         id: 2,
//         name: "Jena Smith",
//         img: "/assets/images/users/user2.svg",
//         age: 29,
//         week: 10,
//         phone: "+1 645 3002",
//     },
//     {
//         id: 3,
//         name: "Sophia Tremblay",
//         img: "/assets/images/users/user3.svg",
//         age: 29,
//         week: 20,
//         phone: "+1 645 3002",
//     },
//     {
//         id: 4,
//         name: "Charlotte Martin",
//         img: "/assets/images/users/user4.svg",
//         age: 29,
//         week: 33,
//         phone: "+1 645 3002",
//     },
// ]

type PatientsListProps = {
  patients?: PatientsModel[];
  selectedPatients?: PatientsModel;
  onSearch?: (text: string) => void;
  onSelecte?: (item: PatientsModel) => void;
};

const PatientsList = (props: PatientsListProps) => {
  // const dispatch = useDispatch();

  const select = (item: PatientsModel) => {
    // dispatch(setSelectedPatients(item));
    if (props.onSelecte) props.onSelecte(item);
  };

  const search = (text: string) => {
    if (props.onSearch) props.onSearch(text);
  };

  return (
    <div
      className={`col-span-12 ${props.selectedPatients ? "md:col-span-3" : "md:col-span-12"} space-y-6 py-8 rounded-2xl overflow-hidden shadow h-full overflow-y-scroll`}
      style={{ backgroundColor: "#FEFBEC" }}
    >
      <HeadingComponent className="px-8" color="#707070">
        Patients list
      </HeadingComponent>

      <div
        className="mx-8 relative py-6 h-16 rounded-3xl"
        style={{ backgroundColor: "#F7F4E6" }}
      >
        <div
          className="absolute top-2 right-3 w-12 h-12 rounded-full flex justify-center cursor-pointer z-30"
          style={{ backgroundColor: "#FEFBEC" }}
        >
          <img
            src="/assets/images/patients/search.svg"
            className="w-6"
            alt=""
          />
        </div>
        <input
          placeholder="Search a patient…"
          onChange={(event) => search(event.target.value)}
          className="absolute inset-0 pl-8 pr-16 w-full z-0 outline-none"
          style={{ backgroundColor: "transparent" }}
        />
      </div>

      <div className="user">
        {
          props.patients &&
          props.patients.map((item, index) => {
            return (
              <Fragment key={index}>
                <div
                  onClick={() => select(item)}
                  className={`cursor-pointer px-8 py-4 item space-y-3 ${
                    props.selectedPatients?.id === item.id
                      ? "border-l-2 border-primary"
                      : ""
                  }`}
                  style={{
                    backgroundColor:
                      props.selectedPatients?.id === item.id ? "#F7F4E6" : "",
                  }}
                >
                  <div className="flex justify-between items-center gap-3">
                    <img src={BASEURLASSETS + item.img} alt="" className="w-12 rounded-full" />
                    <HeadingComponent
                      color="#1D1D1D"
                      fontSize="20px"
                      className="text-primary font-bold"
                    >
                      {item.extra?.name}
                    </HeadingComponent>
                  </div>

                  <div className="flex justify-between items-center gap-3">
                    <HeadingComponent fontSize="20px" color="#A3A3A3">
                      Age
                    </HeadingComponent>
                    <ParagraphComponent
                      color="#707070"
                      className="text-primary"
                    >
                      {item.age}
                    </ParagraphComponent>
                  </div>

                  <div className="flex justify-between items-center gap-3">
                    <HeadingComponent fontSize="20px" color="#A3A3A3">
                      Week of pregnancy
                    </HeadingComponent>
                    <ParagraphComponent
                      color="#707070"
                      className="text-primary"
                    >
                      {item.week}
                    </ParagraphComponent>
                  </div>

                  <div className="flex justify-between items-center gap-3">
                    <HeadingComponent fontSize="20px" color="#A3A3A3">
                      name
                    </HeadingComponent>
                    <ParagraphComponent
                      color="red"
                      className="text-primary border-b"
                      fontSize="16px"
                    >
                      {item.name}
                    </ParagraphComponent>
                  </div>
                </div>
                <div
                  className="bg-gray-gold w-full"
                  style={{ height: "0.4px" }}
                ></div>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default PatientsList;
