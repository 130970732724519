import React from "react";
import "./header.component.scss";
import LogoComponent from "../logo/logo.component";
import { BASEURLASSETS } from "../../api/url.helper";
import { RoutesType } from "../../constant/route.constant";
import { useAppSelector } from "../../store";
import { userState } from "../../store/slice/user.slice";
import { authState } from "../../store/slice/auth.slice";

interface ContainerProps {
  callback?: Function;
  title?: string;
  title_icon?: string;
  isBack?: boolean;
  rightIcon?: string;
  rightCallBack?: Function;
  rightIconIsPopover?: boolean;
  RightComponent?: JSX.Element;
  openMenu?: () => void;

  apnea?: boolean;
  onApana?: (status: boolean) => void;
  onRoute?: (route: string) => void;
  route?: RoutesType
}

const HeaderComponent: React.FC<ContainerProps> = ({
  isBack = false,
  title = "",
  rightCallBack,
  openMenu,
  route,
  onApana,
  apnea,
  onRoute
}) => {
  const { profile } = useAppSelector(authState)
  const getImage = () => {
    const image = localStorage.getItem("image");
    if (!image) return "/assets/images/placeholder.png";
    return BASEURLASSETS + image;
  }
  return (
    <div className="px-4 header-theme shadow z-10 bg-gray-gold flex justify-between items-center">
      <div className="px-2 flex justify-start items-center">
        <LogoComponent onClick={() => onRoute ? onRoute("/dashboard/home") : ""} />
      </div>
      <div className="pr-5">
        <div className="flex justify-between items-center gap-8">
          <img src="/assets/images/notifications.svg" className="cursor-pointer" alt="" />
          <div className="flex gap-2 cursor-pointer">
            <img src={BASEURLASSETS + profile?.img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
