import { PximetriesValueModel } from './../../models/oximetries/report.model';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { DoctorsModel, UserModel } from "../../models/profile/user.model";
import { doctorsRequest, acceptRequest } from '../thunk/user.thunk';
// import { userProfileRequest } from '../thunk/user.thunk';

export interface UserSliceModel {
  profile?: UserModel;
  loading?: boolean;
  extra?: PximetriesValueModel[];
  doctors?: DoctorsModel[];
}

const initialState: UserSliceModel = {
  profile: undefined,
  loading: false,
  extra: []
};

const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.profile = payload;
    },
    setExtra(state, action: PayloadAction<PximetriesValueModel[]>) {
      state.extra = action.payload;
    }
  },
  extraReducers: (builder) => {

    builder.addCase(doctorsRequest.fulfilled, (state, action) => {
      state.loading = false
      state.doctors = action.payload.data;
    });
    builder.addCase(doctorsRequest.rejected, (state, action) => {
      state.loading = false
    });
    builder.addCase(doctorsRequest.pending, (state, action) => {
      state.loading = true
    });

    /// 

    builder.addCase(acceptRequest.fulfilled, (state, action) => {
      state.loading = false
    });
    builder.addCase(acceptRequest.rejected, (state, action) => {
      state.loading = false
    });
    builder.addCase(acceptRequest.pending, (state, action) => {
      state.loading = true
    });

  },
});

export const userState = (state: RootState) => state.user;

export const { setUser, setExtra } = UserSlice.actions;
export default UserSlice.reducer;
