import React from 'react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import ParagraphComponent from '../../../components/typography/paragraph/paragraph.component';
import Routes from '../../../constant/route.constant';

import {
    IonIcon, useIonRouter,
} from "@ionic/react";


import "./setting.page.scss";
import HeadingComponent from '../../../components/typography/heading/heading.component';

type MenuModel = {
    title?: string;
    icon?: string;
    route?: string;
}

const SettingPage = () => {

    const router = useIonRouter();

    const menus: MenuModel[] = [
        {
            title: "Notifications",
            icon: "/assets/images/notifications.svg",
            route: Routes.wristbandConnections.path
        },
        {
            title: "User Management",
            icon: "/assets/images/tabbar/profile.svg",
            route: Routes.wristbandConnections.path
        },
        {
            title: "Profile",
            icon: "/assets/images/medical-advice/doctor.svg",
            route: Routes.wristbandConnections.path
        },
        {
            title: "languages",
            icon: "/assets/images/en.svg",
            route: Routes.wristbandConnections.path
        },
        
    ]

    const onClick = (item: MenuModel) => {
        // router.push(item.route ?? "/");
    }

    return (
        <div className="setting w-full">

            <div className="px-2 flex gap-2" onClick={() => router.goBack()}>
                {/* <img src="/assets/images/menu/arrow-left.svg" alt="" /> */}
                <HeadingComponent className="text-primary">Settings</HeadingComponent>
            </div>

            <ul className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-3">
                {
                    menus.map(menu => {
                        return (
                            <li className="px-8 flex items-center border-b-color py-5 cursor-pointer border rounded-full" onClick={() => onClick(menu)}>
                                <img src={menu.icon} className='mr-2 w-6 h-6' alt="" />
                                <ParagraphComponent className="text-gray" fontSize="22px">{menu.title}</ParagraphComponent>
                                <IonIcon icon={chevronForward} className="ml-auto text-gray" size="small" />
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default SettingPage;