import React from 'react';

type ParagraphComponentProps = {
    children?: React.ReactNode;
    className?: string;
    color?: string;
    fontSize?: string;
    onClick?: () => void;
}

const ParagraphComponent = (props: ParagraphComponentProps) => {
    return (
        <p className={`${props.className} text-font-size-14`} onClick={props.onClick} style={{color: props.color ?? "", fontSize: props.fontSize ?? ""}}>{props.children}</p>
    )
}

export default ParagraphComponent;