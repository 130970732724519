
import { useEffect, useState } from "react";

export const useTimer = () => {

    const [time, setTime] = useState(120);

    useEffect(() => {
        const timer = setInterval(() => {
            if(time <= 0) {
                clearInterval(timer);
                return;
            }
            setTime(time - 1);
        }, 1000);
        return () => {
            console.log("setInterval: " + time);

            clearInterval(timer)
        }
    }, [time])

    const start = (second: number) => {
        setTime(second);
    };

    return { time, start };
}