import { UserModel } from '../../models/profile/user.model';
import { RootState } from '../index';
import { createSlice } from '@reduxjs/toolkit';
import { usersRequest } from '../thunk/users-managment.thunk';

export interface DoctorsModel {
  doctors?: UserModel[],
  loading: boolean;
}

const initialState: DoctorsModel = {
  doctors: [],
  loading: false,
};

const Doctors = createSlice({
  name: 'Doctors',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {

    builder.addCase(usersRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(usersRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.doctors = action.payload.data;
    });

    builder.addCase(usersRequest.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const doctorsState = (state: RootState) => state.doctors;

export default Doctors.reducer;
