import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineReducersModel } from "..";
import { SoundsModel, VibrationPageType } from '../../models/vibration';

export interface VibrationSliceModel {
  sound?: SoundsModel[];
  vibrations?: VibrationPageType[];
}

const initialState: VibrationSliceModel = {
  sound: [],
  vibrations: [],
};

const VibrationSlice = createSlice({
  name: "VibrationSlice",
  initialState,
  reducers: {
    setSound: (state, action: PayloadAction<SoundsModel[]>) => {
      state.sound = action.payload;
    },
    setVibrations: (state, action: PayloadAction<VibrationPageType[]>) => {
      state.vibrations = action.payload;
    },
  },
});

export const vibrationState = (state: combineReducersModel) => state.vibration;

export const { setSound, setVibrations } = VibrationSlice.actions;
export default VibrationSlice.reducer;
