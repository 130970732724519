import authSlice, { AuthSliceModel } from './slice/auth.slice';
import {
  applyMiddleware, configureStore,
} from '@reduxjs/toolkit';
import { createStore, combineReducers } from 'redux';

import thunk from 'redux-thunk';
import apanaSlice, { ApanaSliceModel } from './slice/apana.slice';

import globalSlice, { GlobalSliceModel } from './slice/global.slice';
import pximetriesSlice, { PximetriesSliceModel } from './slice/pximetries.slice';
import sleepSlice, { SleepSliceModel } from './slice/sleep.slice';
import spo2Slice, { Spo2SliceModel } from './slice/spo2.slice';
import PeriodicalUpdateTimeSlice, { PeriodicalUpdateTimeSliceModel } from './slice/update-periodical.slice';
import userSlice, { UserSliceModel } from './slice/user.slice';
import vibrationSlice, { VibrationSliceModel } from './slice/vibration.slice';
import PatientsSlice, { PatientsSliceModel } from './slice/patients.slice';
import UserManagmentSlice from './slice/user-managment.slice';
import doctorsSlice from './slice/doctors.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import DoctorAppointmentSlice from './slice/appointment.slice';

const middleware = applyMiddleware(thunk);

export interface combineReducersModel {
  global: GlobalSliceModel,
  apana: ApanaSliceModel,
  pximetries: PximetriesSliceModel,
  user: UserSliceModel,
  auth: AuthSliceModel,
  vibration: VibrationSliceModel,
  spo2: Spo2SliceModel,
  sleep: SleepSliceModel,
  periodicalUpdateTime: PeriodicalUpdateTimeSliceModel,
  patients: PatientsSliceModel
}

const rootReducer = combineReducers({
  global: globalSlice,
  apana: apanaSlice,
  pximetries: pximetriesSlice,
  user: userSlice,
  vibration: vibrationSlice,
  spo2: spo2Slice,
  auth: authSlice,
  sleep: sleepSlice,
  periodicalUpdateTime: PeriodicalUpdateTimeSlice,
  patients: PatientsSlice,
  userManagment: UserManagmentSlice,
  doctors: doctorsSlice,
  doctorAppointment: DoctorAppointmentSlice,
});

// Note: this API requires redux@>=3.1.0
// const store = createStore(rootReducer, middleware);

const store = configureStore({ reducer: rootReducer})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
