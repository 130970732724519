import React from 'react';

export type ModalProps = {
    children?: React.ReactNode;
    show?: boolean;
    className?: string;
    maxWidth?: string;
    classContainer?: string;
    onClose?: () => void;
}

const Modal = (props: ModalProps) => {
    return (
        <>
            <div className={'fixed z-50 inset-0 overflow-y-auto bg-manatee bg-opacity-40 duration-100 transition-opacity ' + (props.show === true ? 'opacity-1' : 'opacity-0 pointer-events-none')} aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className={`${props.classContainer} flex items-center justify-center min-h-screen pt-4 md:px-4 pb-b-20 text-center sm:block sm:p-0`}>
                    <div className="fixed inset-0 bg-gray-500 bg-black opacity-60 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={props.onClose}></div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    
                    <div style={{backgroundColor: "#FEFBEC"}} className={`w-full mx-4 inline-block align-bottom rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full ${props.className} ${props.maxWidth ?? "sm:max-w-lg"}`}>
                        <div className="rounded">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal;