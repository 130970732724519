import React from "react";
import { AppointmentsModel } from "../../models/appointments";
import HeadingComponent from "../typography/heading/heading.component";
import ParagraphComponent from "../typography/paragraph/paragraph.component";

type DoctorAppointmentProps = {
  appointments?: AppointmentsModel[];
  onUpdate?: (id: number, status: "done" | "canceled") => void;
};

const DoctorAppointment = (props: DoctorAppointmentProps) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonth = (date: string) => {
    const _date = new Date(date);
    var month = _date.getUTCMonth();
    return monthNames[month];
  };

  const getDay = (date: string) => {
    const _date = new Date(date);
    var day = _date.getUTCDate();
    return day;
  };

  const getTime = (date: string) => {
    return date.split(" ")[1];
  };

  const updateStatus = (id: number, status: "done" | "canceled") => {
    if (props.onUpdate) props.onUpdate(id, status);
  };

  const getStatus = (status: "done" | "canceled" | "ahead") => {
    switch (status) {
      case "ahead":
        return { backgroundColor: "#FEF1BE", color: "#AC9642" }
      case "done":
        return { backgroundColor: "#CCFCD9", color: "#4D9861" }
      case "canceled":
        return { backgroundColor: "#F8E6D9", color: "#FD7279" }
    }
  }

  const getBackground = (status: "done" | "canceled" | "ahead") => {
    switch (status) {
      case "ahead":
        return { backgroundColor: "#FEB052" }
      case "done":
        return { backgroundColor: "#4994FC" }
      case "canceled":
        return { backgroundColor: "#FD55BE" }
    }
  }

  const getIcon = (status: "done" | "canceled" | "ahead") => {
    switch (status) {
      case "ahead":
        return "time-circle.svg";
      case "done":
        return "check.svg";
      case "canceled":
        return "close.svg";
    }
  }

  return (
    <div className="px-4 mt-4 space-y-6">
      {props.appointments &&
        props.appointments.map((appointment, index) => {
          return (
            <div className="grid grid-cols-12 gap-3">
              <div
                className="col-span-4 rounded-3xl flex flex-col justify-center items-center h-28"
                style={getBackground(appointment.status)}
              >
                <HeadingComponent
                  fontSize="50px"
                  className="text-white opacity-85"
                >
                  {getDay(appointment.reserve_time)}
                </HeadingComponent>
                <ParagraphComponent
                  fontSize="14px"
                  className="text-white opacity-60"
                >
                  {getMonth(appointment.reserve_time)}
                </ParagraphComponent>
              </div>
              <div className="col-span-8 flex flex-col items-start justify-center space-y-2">
                <div className="w-full flex items-end justify-between">
                  <ParagraphComponent color="#CBB094">
                    {getTime(appointment.reserve_time)}
                  </ParagraphComponent>
                  <div
                    className="flex p-1 rounded-xl gap-2 border"
                    style={getStatus(appointment.status)}
                  >
                    <img
                      src={`/assets/images/doctor-appointment/${getIcon(appointment.status)}`}
                      alt=""
                    />
                    <ParagraphComponent>
                      {appointment.status}
                    </ParagraphComponent>
                  </div>
                </div>
                <HeadingComponent fontSize="20px" className="text-primary">
                  {appointment.name}
                </HeadingComponent>
                <ParagraphComponent color="#868167">
                  {appointment.address}
                </ParagraphComponent>
                {appointment.status === "ahead" && (
                  <div className="flex gap-4">
                    <div
                      className="border p-1 rounded cursor-pointer"
                      onClick={() => updateStatus(appointment.id, "done")}
                      style={getStatus("done")}
                    >
                      done
                    </div>
                    <div
                      className="border p-1 rounded cursor-pointer"
                      onClick={() => updateStatus(appointment.id, "canceled")}
                      style={getStatus("canceled")}
                    >
                      cancele
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DoctorAppointment;
