const getTime = (time: number) => {
  if (time === 0) return "";
  let minutes = Math.floor(time / 60);
  let seconds = time % 60;
  const m = String(minutes).padStart(2, "0");
  const s = String(seconds).padStart(2, "0");
  return (m + ":" + s);
}

export {
  getTime
}