import { IonToast } from "@ionic/react";
import React from "react";
import { useDispatch } from "react-redux";
import { ToastModel } from "../../models/toast.model";
import { globalState, resetToast } from "../../store/slice/global.slice";

interface ToastComponentProps {
  toast?: ToastModel;
}

const ToastComponent: React.FC<ToastComponentProps> = (props: ToastComponentProps) => {
  const dispatch = useDispatch();

  const dismissAction = () => {
    dispatch(resetToast());
  };

  return (
    <IonToast
      isOpen={props.toast !== undefined}
      onDidDismiss={() => dismissAction()}
      message={
        props.toast !== undefined ? props.toast.message : ""
      }
      position="bottom"
      color={
        props.toast && props.toast.status === true
          ? "success"
          : "danger"
      }
      duration={3500}
      buttons={[
        {
          text: "close",
          role: "cancel",
          handler: () => {
            dismissAction();
          },
        },
      ]}
    />
  );
};

export default ToastComponent;
