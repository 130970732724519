import { useIonRouter } from "@ionic/react";
import React, { useEffect } from "react";
import { BASEURLASSETS } from "../../../api/url.helper";
import { UserModel } from "../../../models/profile/user.model";
import { useAppDispatch, useAppSelector } from "../../../store";
import { doctorsState } from "../../../store/slice/doctors.slice";
import { updateStatusRequest, usersRequest } from "../../../store/thunk/users-managment.thunk";

const DoctorsPage = () => {
  const dispatch = useAppDispatch();
  const { doctors } = useAppSelector(doctorsState);
  const router = useIonRouter();

  useEffect(() => {
    doctorsRequest();
  }, []);

  const doctorsRequest = () => {
    dispatch(usersRequest({ user_type: "doctor" }));
  };

  const disableDoctor = (doctor: UserModel) => {
    // updateStatus request
    dispatch(updateStatusRequest({ user_id: doctor.id, disable: doctor.status })).then(response => {
        if(response.meta.requestStatus === "rejected") {
            return;
        }
        doctorsRequest();
    });

  }

  const detail = (doctor: UserModel) => {
    router.push(`/dashboard/${doctor.id}/patients`);
  }

  return (
    <div className="w-full">
      <ul className="flex flex-col gap-4">
        {doctors &&
          doctors.map((doctor) => {
            return (
                <li key={doctor.id} className="flex justify-between items-center border border-gray rounded-xl p-2">
                    {/* <div className="w-full"></div> */}
                    <div className="w-full flex gap-2 items-center"><img src={BASEURLASSETS + doctor.img} alt="" className="w-12 rounded-full" />{doctor.name}</div>
                    <div className="w-full">{doctor.email}</div>
                    {/* <div className="w-full">-{doctor.status}-</div> */}
                    <div className="flex gap-2">
                        <div className={`px-4 py-3 ${doctor.status ? "bg-green" : "bg-red-500"} rounded-2xl text-white cursor-pointer`} onClick={() => disableDoctor(doctor)}>{doctor.status ? "Enabled" : "Disabled"}</div>    
                        <div className="px-4 py-3 bg-primary rounded-2xl text-white cursor-pointer" onClick={() => detail(doctor)}>Detail</div>    
                    </div>
                </li>
            );
          })}
      </ul>
    </div>
  );
};

export default DoctorsPage;
