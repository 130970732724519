import React, { Fragment } from 'react';
import { BASEURLASSETS } from '../../api/url.helper';
import { MedicalAdviceModel } from '../../models/medical_advice';
import SpaceComponent from '../space/space.component';
import HeadingComponent from '../typography/heading/heading.component';
import ParagraphComponent from '../typography/paragraph/paragraph.component';

type MedicalAdviceProps = {
    className?: string;
    medicals?: MedicalAdviceModel[]
    onReplay?: (item: MedicalAdviceModel) => void;
}

const MedicalAdvice = (props: MedicalAdviceProps) => {


    const onReplay = (item: MedicalAdviceModel) => {
        if(!props.onReplay) return;
        props.onReplay(item);
    }


    return (
        <div className={`px-4 mt-4 space-y-3 relative ${props.className}`}>
            {
                props.medicals && props.medicals.map((medical, index) => {
                    return (
                        <Fragment key={index}>
                            <div className="flex items-center gap-3">
                                <img src={BASEURLASSETS + medical.img} alt="" className='w-12 h-12 rounded-full' />
                                <div className="flex w-full items-center justify-between">
                                    <div>
                                        <HeadingComponent fontSize="20px" className="text-primary">{medical.subject}</HeadingComponent>
                                        <HeadingComponent fontSize="20px" className="text-primary">{medical.name}</HeadingComponent>
                                        <ParagraphComponent fontSize="12px" color="#CBB094" className="text-primary">{medical.created_at}</ParagraphComponent>
                                    </div>
                                    <div className="flex gap-2 cursor-pointer" onClick={() => onReplay(medical)}>
                                        <ParagraphComponent color="#868167" className="text-right">Reply</ParagraphComponent>
                                        {/* <img src={} alt="" /> */}
                                        <img src="/assets/images/medical-advice/reply.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* <ParagraphComponent color="#868167">{medical.subject}</ParagraphComponent> */}
                            <ParagraphComponent color="#868167">{medical.message}</ParagraphComponent>
                            {
                                medical.advices.map(item => {
                                    return (
                                        <div className="pl-6 mt-3">
                                            <div className="flex items-center gap-3">
                                                {/* -{item.img}- */}
                                                <img src={BASEURLASSETS + item.img} alt="" className='w-12 h-12 rounded-full' />
                                                <div className="flex flex-col items-start justify-center">
                                                    <HeadingComponent fontSize="20px" className="text-primary">{item.name}</HeadingComponent>
                                                    <ParagraphComponent color="#868167">{item.message}</ParagraphComponent>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="h-0.5 bg-gray"></div>
                        </Fragment>
                    )
                })
            }
            {/* <h2>more ...</h2> */}
            <SpaceComponent space={50} />

        </div>
    )
}

export default MedicalAdvice;