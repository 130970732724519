import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.scss";
import DateObject from "react-date-object";

// import Swiper from "react-id-swiper";
// import Input from '../input/input';
// import Swiper from 'react-id-swiper';
// import 'swiper/css/swiper.css';

// import Swiper from 'swiper/react';
// import 'swiper/css/swiper.css';
// Import Swiper styles
// import 'swiper/css';
// import { Swiper, SwiperSlide } from "swiper/react";

import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import TimeKeeper from "react-timekeeper";

type DatePickerProps = {
  onChange?: (date: any) => void;
  onChangeTime?: (date: any) => void;
  onClose?: (date?: any) => void;
  date?: Date;
  isShow?: boolean;
  // showTimeSelect?: boolean;
  timeIterval?: number;
  isTime?: boolean;

  time?: string;

  // showTimeSelectOnly?: boolean;
};

const DatePickerComponent = (props: DatePickerProps) => {
  const [now, setNow] = useState(moment().hour(0).minute(0));
  // let now = ;
  // const [time, setTime] = useState('12:34pm')

  // const [time, setTime] = useState<{
  //     hour?: string,
  //     minuts?: string,
  // }>();

  // const submitTime = () => {
  //     if (!props.onChange) return;

  //     if (!time?.hour) return;
  //     if (!time?.minuts) return;

  //     props.onChange(time?.hour + ":" + time?.minuts);
  // }

  // useEffect(() => {
  //     if (props.timeIterval) {
  //         const split = props.time?.split(":");
  //         if (split && split?.length >= 2) {
  //             setTime({ hour: split[0], minuts: split[1] })
  //         }
  //     }
  // }, [props.time])

  // useEffect(() => {
  //     debugger;

  // }, [props.date])

  // const params = {
  //     slidesPerView: 3,
  //     pagination: {
  //         //     el: '.swiper-pagination',
  //         //     type: 'bullets',
  //         clickable: true
  //     },
  //     scrollbar: { draggable: true },

  //     // navigation: {
  //     //     nextEl: '.swiper-button-next',
  //     //     prevEl: '.swiper-button-prev'
  //     // },
  //     spaceBetween: 30
  // }

  var times = [],
    // , periods = ['AM', 'PM']
    hours = Array.from(Array(24).keys()),
    prop = null,
    hour = null,
    min = null;

  // for (prop in periods) {
  for (hour in hours) {
    for (min = 0; min < 60; min += props.timeIterval ?? 15) {
      times.push(
        ("0" + hours[hour]).slice(-2) + ":" + ("0" + min).slice(-2) + " "
      ); // + periods[prop]);
    }
  }
  // }
  const onChange = (date: any) => {
    if (!props.onChange) return;
    if (props.timeIterval) {
      props.onChange(date);
      return;
    }

    var dateObject = new DateObject(date);
    const dateFormat = convertDateTo24Hour(dateObject.format("YYYY-MM-DD hh:mm:a"));
    props.onChange(dateFormat);
    if (props.onClose) props.onClose();
  };

  const convertDateTo24Hour = (date: string) => {
    // const date =
    var elem = date.split(" ");
    var stSplit = elem[1].split(":"); // alert(stSplit);
    var stHour = Number(stSplit[0]);
    var stMin = stSplit[1];
    var stAmPm = stSplit[2];
    var newhr = 0;
    var ampm = "";
    var newtime = "";
    if (stAmPm == "pm") {
      if (stHour != 12) {
        stHour = stHour * 1 + 12;
      }
    } else if (stAmPm == "am" && stHour == 12) {
      stHour = stHour - 12;
    } else {
      stHour = stHour;
    }
    return elem[0] + " " + stHour + ":" + stMin;
  };

  const format = "H:mm";

  useEffect(() => {
    if (props.timeIterval) {
      // debugger
      const split = props.time?.split(":");
      if (split && split?.length >= 2) {
        setNow(moment().hour(Number(split[0])).minute(Number(split[1])));
      }
    }
  }, [props.time]);

  function onChangeTime(value: moment.Moment) {
    if (!props.onChangeTime) return;
    try {
      const time = value.format(format);
      if (time.length !== 5) return;

      const split = time.split(":");
      if (split.length < 2) return;

      let h = Number(split[0]);
      let m = split[1];
      // let a = split[2];

      // if (a === "pm") {
      //     h += 12;
      // }

      props.onChangeTime(h + ":" + m);
    } catch (error) {}
  }

  return (
    <>
      <div
        className={
          "fixed z-50 inset-0 overflow-y-auto bg-manatee bg-opacity-40 duration-100 transition-opacity " +
          (props.isShow === true
            ? "opacity-1"
            : "opacity-0 pointer-events-none")
        }
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className={`flex items-center justify-center min-h-screen pt-4 md:px-4 pb-b-20 text-center sm:block sm:p-0`}
        >
          <div
            className="fixed inset-0 bg-gray-500 transition-opacity bg-black opacity-50"
            aria-hidden="true"
            onClick={props.onClose}
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            style={{ maxHeight: "70vh" }}
            className={`border border-white mx-4 p-3 inline-block align-center bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle sm:max-w-lg`}
          >
            <div className="w-full relative rounded">
              {/* <div className="text-white">-{JSON.stringify(props.time ?? "12:00")}-</div> */}
              {/* {
                                props.timeIterval ? (
                                    <div className="flex justify-center flex-col gap-2">
                                        <TimeKeeper
                                            time={props.time}
                                            onChange={(newTime) => { if (props.onChangeTime) { props.onChangeTime(newTime.formatted24) } }}
                                            switchToMinuteOnHourSelect
                                            forceCoarseMinutes
                                            closeOnMinuteSelect
                                            hour24Mode
                                        />
                                        <button className="border py-1 px-4 border-white rounded text-white" onClick={() => { if (props.onClose) props.onClose() }} style={{ border: "1px solid" }}>submit</button>
                                    </div>

                                ) : (
                                    
                                )
                            } */}

              <DatePicker
                selected={props.date}
                onChange={(date) => onChange(date)}
                inline
                // shouldCloseOnSelect
                minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
                // showTimeInput={true}
                showTimeSelect={true}

                // dateFormat="MMMM d, yyyy"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePickerComponent;
