import React, { useState } from "react";
import { AppointmentsModel } from "../../models/appointments";
import { MedicalAdviceModel } from "../../models/medical_advice";
import { DoctorsModel } from "../../models/profile/user.model";
import { useAppSelector } from "../../store";
import { doctorAppointmentState } from "../../store/slice/appointment.slice";
import DatePickerComponent from "../date-picker/date-picker";
import DoctorAppointment from "../doctor-appointment";
import MedicalAdvice from "../medical-advice";
import Modal from "../modal-tailwind";
import HeadingComponent from "../typography/heading/heading.component";
import ParagraphComponent from "../typography/paragraph/paragraph.component";

type MessagesDoctorProps = {
    medicals?: MedicalAdviceModel[];
    appointments?: AppointmentsModel[];
    doctors?: DoctorsModel[];
    onSubmit?: (
        message: string,
        to_user_id?: number,
        message_id?: number,
        subject?: string
    ) => void;
    onSubmitAppointments?: (date: string) => void;
    onTabAppointmentChange?: () => void;
    onUpdateAppointment?: (id: number, status: "done" | "canceled") => void;
};

const MessagesDoctor = (props: MessagesDoctorProps) => {
    const [date, setDate] = useState<string>("");
    const [tab, setTab] = useState<"medical-advice" | "doctor-appointment">(
        "medical-advice"
    );
    const tabToggle = (_tab: "medical-advice" | "doctor-appointment") => {
        setTab(_tab);
        // if(_tab === "doctor-appointment") {
        //     if(props.onTabAppointmentChange) props.onTabAppointmentChange()
        // }
    };
    // const { doctors, appointments } = useAppSelector(doctorAppointmentState);
    const [doctorId, setDoctorId] = useState<string>();

    const [showModal, setShowModal] = useState<{
        status: boolean;
        medical?: MedicalAdviceModel;
    }>({ status: false });
    const [calendar, setCalendar] = useState({ show: false, date: "" });
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const onReplay = (medical?: MedicalAdviceModel) => {
        setShowModal({ status: true, medical });
    };

    const submit = () => {
        if (tab === "medical-advice") {
            submitMedical();
        } else if (tab === "doctor-appointment") {
            if (props.onSubmitAppointments) props.onSubmitAppointments(date);
        }
        setShowModal({ status: false });
    };

    const submitMedical = () => {
        if (!props.onSubmit) return;
        // if (showModal.medical) {
        props.onSubmit(message, showModal.medical?.id, showModal.medical?.id, subject);
        // return;
        // }
    };

    const updateStatus = (id: number, status: "done" | "canceled") => {
        if (props.onUpdateAppointment) props.onUpdateAppointment(id, status);
    };

    return (
        <div
            className="relative mt-4 md:mt-8 row-span-3 px-2 mx-auto w-full rounded-2xl shadow"
            style={{ backgroundColor: "#FEFBEC" }}
        >
            <div className="bg-main px-4 py-2 rounded-tr-3xl rounded-tl-3xl">
                <ul className="flex justify-between bg-gray-gold rounded-full mx-auto p-2 w-80">
                    <li
                        className={`cursor-pointer rounded-full py-4 w-full text-center ${tab === "medical-advice" ? "" : "opacity-40"
                            } text-primary`}
                        style={{
                            backgroundColor: tab === "medical-advice" ? "#FEFBEC" : "",
                        }}
                        onClick={() => tabToggle("medical-advice")}
                    >
                        Messages
                    </li>
                    <li
                        className={`cursor-pointer rounded-full py-4 w-full text-center ${tab === "doctor-appointment" ? "" : "opacity-40"
                            } text-primary`}
                        style={{
                            backgroundColor: tab === "doctor-appointment" ? "#FEFBEC" : "",
                        }}
                        onClick={() => tabToggle("doctor-appointment")}
                    >
                        Appointments
                    </li>
                </ul>
            </div>

            <Modal
                show={showModal.status}
                onClose={() => setShowModal({ status: false })}
            >
                <div className="p-8 shadow space-y-8">
                    <div className="flex justify-between">
                        <ParagraphComponent fontSize="20px" color="#1D4068">
                            {tab === "medical-advice"
                                ? "Write a message for"
                                : "Arranging an appointment For"}{" "}
                        </ParagraphComponent>
                        <img
                            src="/assets/images/close.svg"
                            className="cursor-pointer"
                            onClick={() => setShowModal({ status: false })}
                            alt=""
                        />
                    </div>
                    <div className="flex gap-3">
                        <img src="/assets/images/users/user2.svg" alt="" />
                        <div className="flex flex-col items-start justify-center">
                            <HeadingComponent fontSize="20px" className="text-primary">
                                {showModal.medical?.name}
                            </HeadingComponent>
                            {/* <ParagraphComponent color="#868167">{showModal.medical?.name}</ParagraphComponent> */}
                        </div>
                    </div>

                    {tab === "medical-advice" && (
                        <>
                            {!showModal.medical && (
                                <input
                                    onChange={(e) => setSubject(e.target.value)}
                                    value={subject}
                                    className="p-4 border border-gray w-full rounded-xl outline-none"
                                    placeholder="please enter subject"
                                />
                            )}
                            <textarea
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                rows={5}
                                className="p-4 border border-gray w-full rounded-xl outline-none"
                                style={{ backgroundColor: "#F7F4E6", color: "#69717B" }}
                            ></textarea>
                        </>
                    )}

                    {tab === "doctor-appointment" && (
                        <div className="space-y-5">
                            <div className="flex gap-8">
                                <ParagraphComponent color="#A3A3A3">
                                    Date and time
                                </ParagraphComponent>
                                <div
                                    className="flex justify-between items-center gap-4 cursor-pointer"
                                    onClick={() => setCalendar({ ...calendar, show: true })}
                                >
                                    <ParagraphComponent className="w-44 border-b border-b-color pb-2">
                                        {date}
                                    </ParagraphComponent>
                                    <img
                                        src="/assets/images/message/calendar.svg"
                                        className="w-3 h-3"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-end items-end">
                        <div
                            className="bg-primary flex py-4 px-8 cursor-pointer rounded-full text-white gap-2"
                            onClick={submit}
                        >
                            <ParagraphComponent fontSize="16px" className="font-bold">
                                send
                            </ParagraphComponent>
                            <img
                                src={`/assets/images/${tab === "medical-advice" ? "send" : "tick-square"
                                    }.svg`}
                                alt=""
                            />
                        </div>
                    </div>

                    {/* <HeadingComponent>Write a message for </HeadingComponent> */}
                </div>
            </Modal>

            <div
                onClick={() => onReplay()}
                className="bg-white z-20 shadow cursor-pointer rounded-full w-16 h-16 flex justify-center items-center absolute right-10 bottom-10"
            >
                <img
                    src={`/assets/images/message/${tab === "medical-advice" ? "edit-square" : "plus"
                        }.svg`}
                    className="w-5 h-5"
                    alt=""
                />
            </div>

            <DatePickerComponent
                isShow={calendar.show}
                onChange={(value) => {
                    setDate(value);
                    // setCalendar({ ...calendar, show: false })
                }}
                // onChangeTime={onChangeTime}
                onClose={() => setCalendar({ ...calendar, show: false })}
            // timeIterval={30}
            // fromDate={calendar.fromDate}
            // time={calendar.isFrom ? calendar.fromDate : calendar.toDate}
            />

            <div className="overflow-y-scroll h-full" style={{ height: "45vh" }}>
                {tab === "medical-advice" && (
                    <MedicalAdvice
                        className=""
                        onReplay={(item) => onReplay(item)}
                        medicals={props.medicals}
                    />
                )}
                {tab === "doctor-appointment" && (
                    <DoctorAppointment appointments={props.appointments} onUpdate={updateStatus} />
                )}
            </div>
        </div>
    );
};

export default MessagesDoctor;
