import React, { useEffect, useState } from "react";
import { BASEURLASSETS } from "../../../api/url.helper";
import ButtonComponent from "../../../components/button/button.component";
import CropperImage from "../../../components/cropper";
import Input from "../../../components/input/input";
import ParagraphComponent from "../../../components/typography/paragraph/paragraph.component";
import { UserModel } from "../../../models/profile/user.model";
import { useAppDispatch, useAppSelector } from "../../../store";
import { userManagmentState } from "../../../store/slice/user-managment.slice";
import {
  createUserRequest,
  updateStatusRequest,
  usersRequest,
} from "../../../store/thunk/users-managment.thunk";

const UserManagmentPage = () => {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  // const [role, setRole] = useState<"doctor" | "user">("doctor");
  const [status, setStatus] = useState<boolean>(true);

  const [roleUser, setRoleUser] = useState<"doctor" | "user">("user");
  const dispatch = useAppDispatch();
  const { users, loading } = useAppSelector(userManagmentState);
  const [image, setImage] = useState<string>();
  const [imageData, setImageData] = useState<string>();

  useEffect(() => {
    listUsers();
  }, []);

  const create = () => {
    if (!email) {
      alert("Please enter email number");
      return;
    }
    if (!name) {
      alert("Please enter name");
      return;
    }
    if (!imageData) {
      alert("Please choose image");
      return;
    }

    dispatch(createUserRequest({ email, name, status: status ? 1 : 0, image: imageData })).then(
      (response) => {
        if (response.meta.requestStatus === "rejected") {
          return;
        }
        listUsers();
        alert("User created successfully");
      }
    );
  };

  const listUsers = () => {
    dispatch(usersRequest({ user_type: roleUser }));
  };

  const updateStatus = (user: UserModel) => {
    dispatch(
      updateStatusRequest({ user_id: user.id, disable: user.status })
    ).then((response) => {
      if (response.meta.requestStatus === "rejected") {
        return;
      }
      listUsers();
    });
  };

  const submitImage = (data: string) => {
    setImage(undefined);
    setImageData(data);
  }

  useEffect(() => {
    listUsers();
  }, [roleUser]);

  const onFile = (files: FileList | null) => {
    if (!files || files.length === 0) return;
    // setImage(files[0]);

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);

  }

  return (
    <div className="w-full flex flex-col gap-8">
      <div className="flex flex-col gap-6 border border-gray p-3 rounded-xl">
        <h2 className="mt-8">Create new doctor</h2>
        <div className="flex gap-4 items-center">
          <img src={imageData} className="w-16 rounded-full border bg-gray" alt="" />
          <Input value={email} onChange={setEmail} placeholder="email" />
          <Input value={name} onChange={setName} placeholder="name" />
        </div>
        <input type="file" name="" id="profile-image" onChange={(e) => onFile(e.target.files)} />
        {
          image && (
            <CropperImage onSubmit={submitImage} image={image} onCancel={() => setImage(undefined)} loading={loading} />
          )
        }

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={status}
            onChange={(e) => setStatus(e.target.checked)}
            id="status"
          />
          <label htmlFor="status">enable user</label>
        </div>
        <ButtonComponent
          className="text-white font-bold md:w-96 mx-auto"
          circle={false}
          label="Create user"
          active={true}
          onClick={create}
        ></ButtonComponent>
      </div>

      <div className="flex flex-col gap-4">

        <h2>{roleUser === "doctor" ? "Doctores" : "Users"}</h2>
        <select
          name=""
          id=""
          className="p-3 rounded-xl"
          value={roleUser}
          onChange={(e) =>
            setRoleUser(e.target.value === "user" ? "user" : "doctor")
          }
        >
          <option value="doctor">doctor</option>
          <option value="user">user</option>
        </select>
        <ul className="flex flex-col gap-2">
          {users &&
            users.map((user) => {
              return (
                <li className="grid grid-cols-2 md:flex gap-4 items-center border border-gray justify-between rounded-xl p-2">
                  {/* <h4>{user.email}</h4> */}
                  <div className="flex gap-2 items-center w-24">
                    <img src={BASEURLASSETS + user.img} alt="" className="w-12 rounded-full" />
                    <h4>{user.name}</h4>
                  </div>
                  <h4 className={`${user.status ? "text-green" : "text-red-500"} w-24`}>{user.status ? "Active" : "Diactive"}</h4>
                  {/* <h4>role: {user.role}</h4> */}
                  {/* <ButtonComponent active={true} onClick={() => updateStatus(user)} className="text-white" >
                    disable
                  </ButtonComponent> */}
                  <div className={`px-4 py-3 ${user.status ? "bg-red-500" : "bg-green"} rounded-2xl text-white cursor-pointer`} onClick={() => updateStatus(user)}>{user.status ? "Disable" : "Enable"}</div>

                  {/* <div
                    className="border cursor-pointer p-2 rounded-xl"
                    onClick={() => updateStatus(user)}
                  >
                    disable
                  </div> */}
                </li>
              );
            })}
        </ul>
      </div>

    </div>
  );
};

export default UserManagmentPage;
