import { DoctorsModel, UserModel } from "../../models/profile/user.model";
import ResponseModel from "../../models/response.model";
import GenericThunk from ".";
import UserManagmentService from "../../api/user-managment";
import AppointmentsService from "../../api/appointments";

// export const userProfileRequest = GenericThunk<ResponseModel<UserModel>>(
//   UserManagmentService.profile()
// );

export const doctorsRequest = GenericThunk<
  ResponseModel<DoctorsModel[]>
>(AppointmentsService.doctors());

export const acceptRequest = GenericThunk<
  ResponseModel<DoctorsModel[]>, { id: number, status: string }
>(AppointmentsService.acceptRequest());
