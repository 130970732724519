import React from 'react';
import { IonIcon } from '@ionic/react';

type InputProps = {
    value?: string;
    onChange?: (value: string) => void;
    onClickIcon?: () => void;
    onClick?: () => void;
    icon?: string | undefined
    placeholder?: string | undefined
    readOnly?: boolean;
    type?: "text" | "number" | "tel" | "email"
    align?: "text-center" | "text-left" | "text-right"
}

const Input = (props: InputProps) => {

    const onChange = (value: string) => {
        if (!props.onChange) return;
        props.onChange(value);
    }
    return (
        <div className="input-continer w-full relative">

            {
                props.icon && (
                    <IonIcon icon={props.icon} onClick={props.onClickIcon} className="cursor-pointer text-gray absolute top-4 right-3" size="large" />
                )
            }
            <input
                onClick={props.onClick}
                type={props.type ?? "text"}
                readOnly={props.readOnly}
                onChange={(event) => onChange(event.target.value)}
                value={props.value}
                placeholder={props.placeholder}
                className={`w-full bg-transparent text-black border-b border-gray pb-1 focus:outline-none ${props.align ?? "text-left"}`}
            />
        </div>
    )
}

export default Input;