import React, { useEffect, useRef, useState } from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./index.scss";
import ParagraphComponent from '../typography/paragraph/paragraph.component';

type SliderComponentProps = {
    week?: number;
}

const SliderComponent = (props: SliderComponentProps) => {
    const slider = useRef<Slider>(null);

    const [settings, setSetting] = useState<any>(undefined);
    const [reset, setReset] = useState<boolean>(true);

    useEffect(() => {
        setSetting(
            {
                infinite: true,
                arrows: false,
                speed: 500,
                centerMode: true,
                slidesToShow: 11,
                initialSlide: (props.week ?? 5) - 1,
                swipeToSlide: true,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                ]
            }
        );
        setReset(false);
        setTimeout(() => {
            setReset(true);
            if (slider && slider?.current) slider!.current!.slickGoTo((props.week ?? 5) - 1, true);
        }, 100);

    }, [props.week])



    const next = () => {
        slider.current?.slickNext();
    }

    const prev = () => {
        slider.current?.slickPrev();
    }

    if (!settings) {
        return <div className="flex h-full justify-center items-center">Loading...</div>;
    }

    return (
        <div className="relative">
            {/* -{props.week}- */}
            <img src="/assets/images/weeks/arrow-left.svg" className="absolute left-0 top-8 w-8 h-8 cursor-pointer" alt="" onClick={prev} />
            <span className="absolute left-8 top-0 bottom-0 bg-red-600 w-8 md:w-32 linear-gradient left"></span>
            <span className="absolute right-8 top-0 bottom-0 bg-red-600 w-8 md:w-32 linear-gradient right"></span>
            {
                reset && (
                    <Slider className="mx-8" ref={slider} {...settings} >
                        {
                            Array.from({ length: 45 }, (_, i) => i + 1).map((item, index) => {
                                return (
                                    <div key={index} className={`slick-item text-center flex flex-col justify-center items-center space-y-0`}>
                                        <h6 className="text-center">{item}</h6>
                                        <ParagraphComponent>week</ParagraphComponent>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                )
            }

            <img src="/assets/images/weeks/arrow-right.svg" className="absolute right-0 top-8 w-8 h-8 cursor-pointer" alt="" onClick={next} />

        </div>

    )
}

export default SliderComponent;