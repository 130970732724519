import { DoctorsModel } from "../../models/profile/user.model";
import ResponseModel from "../../models/response.model";
import GenericThunk from ".";
import AppointmentsService from "../../api/appointments";
import { AppointmentsModel } from "../../models/appointments";

export const doctorRequest = GenericThunk<ResponseModel<DoctorsModel[]>>(
  AppointmentsService.doctorsRequest()
);

export const appointmentsSendRequest = GenericThunk<ResponseModel<AppointmentsModel[]>, {user_id: number, reserve_time: string}>(
  AppointmentsService.appointmentsSend()
);

export const appointmentsRequest = GenericThunk<
  ResponseModel<AppointmentsModel[]>, {user_id?: number}
>(AppointmentsService.appointments());

export const appointmentUpdateRequest = GenericThunk<
  ResponseModel<AppointmentsModel[]>, {id?: number, status?: "done" | "canceled", user_id?: number}
>(AppointmentsService.appointmentsUpdate());
