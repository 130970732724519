import { IRequestConfig } from '..';

namespace UserManagmentService {
  export const user_create = (): IRequestConfig => {
    return {
      url: "/v1/dashboard/user/create",
      method: 'POST',
      key: 'dashboard/user/create',
      payload: 'data',
    };
  };

  export const users = (): IRequestConfig => {
    return {
      url: "/v1/dashboard/user",
      method: 'GET',
      key: 'dashboard/user',
      payload: 'params',
    };
  };

  export const updateStatus = (): IRequestConfig => {
    return {
      url: "/v1/dashboard/user/update-status",
      method: 'PATCH',
      key: 'dashboard/user/update-status',
      payload: 'data',
    };
  };
}

export default UserManagmentService;
