import AxiosHelper from "./axios.helper";

export interface IRequestConfig {
  url?: string;
  method?: "GET" | "DELETE" | "POST" | "PUT" | "PATCH";
  headers?: any;
  params?: any;
  data?: any;
  key: string;
  payload?: "data" | "params";
}


namespace Service {
  export const request = <T = any, R = any>(
    request: IRequestConfig,
    params: R
  ) => {

    if (request.payload === "data") {
      request.data = params;
    } else if (request.payload === "params") {
      request.params = params;
    }

    return AxiosHelper.request<T>({
      url: request.url,
      method: request.method,
      headers: request.headers,
      params: request.params,
      data: request.data,
    });
  };
  
}

export default Service;