import React from "react";
import "./loading.component.scss";

interface ContainerProps {
  isLoading?: boolean;
}

const LoadingComponent: React.FC<ContainerProps> = (props: ContainerProps) => {

  return (
    <>
      {props.isLoading === true ? (
        <div className="spinner"></div>
      ) : null}
    </>
  );
};

export default LoadingComponent;
